import React, { useEffect, useRef, useState, useContext } from "react";
import { Container, Row } from "react-bootstrap";
import "./PrivacyPolicy.css";
import { Link } from "react-router-dom";
import { TermsAndConditionData } from "../../utils/apis/master/Master";
import parse from "html-react-parser";
const TermsAndCondition = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);
  const [data, setData] = useState();
  const getAllData = async () => {
    const res = await TermsAndConditionData();
    if (res?.success) {
      setData(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);
  return (
    <>
      <section className="privacy-policy">
        <section className="About_banner_sec">
          <div className="container-fluid p-0">
            <img
              className="banner-img"
              src={
                process.env.PUBLIC_URL +
                "/assets/images/subscription/TermsConditions.png"
              }
            />
            <div className="overlayblack"></div>
            <div className="overlay-text">
              <h3 className="banner-title">Terms & Conditions</h3>
              {/* <h6 className='subtitle'>Get Your perfect fit</h6> */}
            </div>

            <div className="home-return-pos">
              <div className="container">
                <Link to="/">Home</Link>
              </div>
            </div>
          </div>
        </section>

        <Container>
          <div className="background-card med-top">
            <img
              className="curve-img"
              src={
                process.env.PUBLIC_URL +
                "/assets/images/about-us/banner/layer.png"
              }
            />
            <Row className="mt-5">
              <div className="col-lg-10 mx-auto">
                <div className="heading-holder mb-4">
                  <p>
                    {typeof data?.description === "string"
                      ? parse(data?.description)
                      : ""}
                  </p>
                </div>
                {/* <div className='heading-holder mb-4'>
                                    <p> By consenting to the terms on this site (the “Website”), you are entering into the following Terms and Conditions established with FnS. PLEASE REVIEW
                                        THESE TERMS THOUGHTFULLY. If you disagree, please leave the site and refrain from further use.</p>
                                </div>
                                <div className='heading-holder mb-4'>
                                    <h2>1. Definitions</h2>
                                    <p> Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when a unknown printer took a galley of type and scrambled it to make a type
                                        specimen book.</p>

                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when a unknown printer took a galley of type and scrambled it to make a type
                                        specimen book.</p>

                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when a unknown printer took a galley of type and scrambled it to make a type
                                        specimen book.</p>
                                </div>
                                <div className='heading-holder mb-4'>
                                    <h2>2. Privacy</h2>
                                    <p> By accessing this Website, you are accepting FnSs Privacy Policy, the details of which can be found at http://FnScom/privacy-policy/.</p>

                                </div>
                                <div className='heading-holder mb-4'>
                                    <h2>3. Orders</h2>
                                    <p> A customer makes a purchase on the FnS Website and either -</p>
                                    <p> a) provides their own address for direct shipping, or b) provides the email address of the recipient.</p>

                                    <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when
                                        a unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when a unknown printer took a galley of type and scrambled it to make a type
                                        specimen book.</p>

                                    <p>   Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into
                                        electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                                        and more recently with desktop publishing software like Aldu PageMaker including versions of Lorem Ipsum.</p>

                                </div>
                                <div className='heading-holder mb-4'>
                                    <h2>4. Payment</h2>

                                    <p> The customer will complete payment upon placing the order, which includes taxes and shipping charges. Payments are handled by FnS's third-party payment
                                        processor, & the customer is accountable for reviewing all relevant terms and policies. FnS bears no responsibility for the actions or oversights of the payment
                                        processing entity.</p>

                                </div> */}
              </div>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default TermsAndCondition;
