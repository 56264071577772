import React, { useEffect, useRef, useState, useContext } from "react";
import "./Studio-inner.css";
import Breadcrumb_comp from "./Breadcrumb-comp/Breadcrumb_comp";
import Studio_card_one from "./Studio-card/Studio_card_one";
import Studio_slider from "./studio-slider/Studio_slider";
import Tabs_section from "../Studio_header/tabs-section/Tabs_section";
import { Context } from "../../utils/context";
import { mediaSpaceDetails } from "../../utils/apis/MediaSpace/MediaSpace";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
const Studio_inner = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  const [data, setData] = useState();

  const { id } = useParams();
  const { IMG_URL } = useContext(Context);
  const getAllData = async () => {
    const res = await mediaSpaceDetails(id);
    if (res?.success) {
      setData(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };
  useEffect(() => {
    getAllData();
  }, [id]);
  return (
    <>
      <div className="top-stud-bg"></div>

      <Breadcrumb_comp data={data} />
      <Studio_card_one data={data} />
      <Studio_slider data={data} />
      <Tabs_section data={data} />
    </>
  );
};

export default Studio_inner;
