import { getData } from "../../api";

// Blog
export const HomeAbout = async (id) => {
  try {
    return await getData(`/without-login/about-us/about`);
  } catch (error) {
    console.log(error);
  }
};

export const OurMission = async (id) => {
  try {
    return await getData(`/about/our-mission`);
  } catch (error) {
    console.log(error);
  }
};

export const ReviewCount = async (id) => {
  try {
    return await getData(`/without-login/all-review_count`);
  } catch (error) {
    console.log(error);
  }
};

// Faq

export const TeamAbout = async (id) => {
  try {
    return await getData(`/about/team`);
  } catch (error) {
    console.log(error);
  }
};

export const AboutBanner = async (id) => {
  try {
    return await getData(`/about/banner`);
  } catch (error) {
    console.log(error);
  }
};

export const Counter = async (id) => {
  try {
    return await getData(`/about-us/counter`);
  } catch (error) {
    console.log(error);
  }
};
