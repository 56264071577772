import React from "react";
import "./Home-inquiry.css";

import Enquiry_form_modal from "../../common-section/Modals/enquiry-form-modal/Enquiry_form_modal";

const Home_inquiry = () => {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <section className="Home_inquiry_sec">
        <div className="overlay-bg"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6 my-auto">
              <h1 className="openyuor">
                Open your door of opportunity before someone else does
              </h1>
            </div>
            <div className="col-md-6 my-auto text-md-end text-center">
              <button
                className="enquiry-btn btn"
                onClick={() => setModalShow(true)}
              >
                ENQUIRE
              </button>
            </div>
          </div>
        </div>

        <Enquiry_form_modal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </section>
    </>
  );
};

export default Home_inquiry;
