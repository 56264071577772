import { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./book-studio-modal.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";

const Book_studio_modal = (props) => {
  const { IMG_URL, signin } = useContext(Context);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="Book_studio_modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img className="mod-img" src={IMG_URL + props?.image} />

          <div className="text-center mt-2">
            <h5 className="stuh5">{props?.name}</h5>
            <p className="pick-p">Pick your best</p>

            <div className="d-flex justify-content-center">
              {/* <Link to={`/hourly-bookings/${props?.studio_id}`}>
                <button className="mod-btn">HOURLY</button>
              </Link> */}
              <Link to={`/daily-bookings/${props?.studio_id}`}>
                <button className="mod-btn">Book</button>
              </Link>
              {/* <Link to="/hourly-bookings"><button className='mod-btn'>HOURLY</button></Link>
                            <Link to="/daily-bookings"><button className='mod-btn'>DAILY</button></Link> */}
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default Book_studio_modal;
