import React, { useEffect, useRef, useState, useContext } from "react";
import "./Banner.css";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import { Context } from "../../../utils/context";
import { Homebanner } from "../../../utils/apis/Home/Homeapi";
import Slider from "react-slick";

// import required modules
import { EffectFade } from "swiper/modules";
function Banner() {
  const [data, setData] = useState();
  const { IMG_URL } = useContext(Context);
  const getAllData = async () => {
    const res = await Homebanner();
    if (res?.success) {
      setData(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    autoplay: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
  };

  return (
    <section className="home-banner">
      <div className="container-fluid p-0">
        <div className="d-flex">
          <div className="sec-1">
            <div className="img-div">
              <img
                className="ban-img"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/homepage/home-banner/bg1.png"
                }
              />
            </div>

            <div className="slider-div">
              <p className="slidemain-t">Explore a new you</p>
              <Swiper
                pagination={{
                  dynamicBullets: true,
                  clickable: true,
                }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Pagination, Autoplay]}
                className="mySwiper"
              >
                {data?.map((val) => (
                  <SwiperSlide>
                    <div className="main-div">
                      {/* <h1 className='title'>Find a space.</h1> */}
                      <h1 className="title">{val?.name}</h1>
                    </div>
                  </SwiperSlide>
                ))}
                {/* <SwiperSlide>
                  <div className='main-div'>
                    <h1 className='title'>Find a space.</h1>
                    <h1 className='title'>Fulfill your vision.</h1>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className='main-div'>
                    <h1 className='title'>Find a space.</h1>
                    <h1 className='title'>Fulfill your vision.</h1>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className='main-div'>
                    <h1 className='title'>Find a space.</h1>
                    <h1 className='title'>Fulfill your vision.</h1>
                  </div>
                </SwiperSlide>*/}
              </Swiper>
            </div>
          </div>
          <div className="sec-2">
            {/* <img
              className="ban-img"
              src={
                process.env.PUBLIC_URL +
                "/assets/images/homepage/home-banner/bg2.png"
              }
            /> */}
            <div className="slider-container">
              <Slider {...settings}>
                {data?.map((val) => (
                  <div>
                    <img className="ban-img" src={IMG_URL + val?.image} />
                  </div>
                ))}
                {/* <div>
                  <img className="ban-img" src={process.env.PUBLIC_URL + "/assets/images/homepage/home-banner/bg3.jpeg"} />
                </div>
                <div>
                  <img className="ban-img" src={process.env.PUBLIC_URL + "/assets/images/homepage/home-banner/bg4.jpg"} />
                </div>
                <div>
                  <img className="ban-img" src={process.env.PUBLIC_URL + "/assets/images/homepage/home-banner/bg3.jpeg"} />
                </div> */}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;

// import React, { useEffect, useRef, useState, useContext } from "react";
// import "./Banner.css";

// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/pagination";
// import "swiper/css/effect-fade";
// import { Pagination, Autoplay } from "swiper/modules";
// import { Context } from "../../../utils/context";
// import { Homebanner } from "../../../utils/apis/Home/Homeapi";
// // import required modules
// import { EffectFade } from "swiper/modules";
// function Banner() {
//   const [data, setData] = useState();
//   const { IMG_URL } = useContext(Context);
//   const getAllData = async () => {
//     const res = await Homebanner();
//     if (res?.success) {
//       setData(res?.data);
//     } else {
//       console.error("Error fetching  data:", res?.error);
//     }
//   };

//   useEffect(() => {
//     getAllData();
//   }, []);
//   console.log(data, "data home");
//   return (
//     <section className="home-banner">
//       <div className="container-fluid p-0">
//         <div className="row">
//           <div className="col-md-6">
//             <div className="sec-1">
//               <div className="img-div">
//                 <img
//                   className="ban-img"
//                   src={
//                     process.env.PUBLIC_URL +
//                     "/assets/images/homepage/home-banner/bg1.png"
//                   }
//                 />
//               </div>

//               <div className="slider-div">
//                 <p className="slidemain-t">Explore a new you</p>
//                 <Swiper
//                   pagination={{
//                     dynamicBullets: true,
//                     clickable: true,
//                   }}
//                   autoplay={{
//                     delay: 2500,
//                     disableOnInteraction: false,
//                   }}
//                   modules={[Pagination, Autoplay]}
//                   className="mySwiper"
//                 >
//                   {data?.map((val) => (
//                     <SwiperSlide>
//                       <div className="main-div">
//                         {/* <h1 className='title'>Find a space.</h1> */}
//                         <h1 className="title">{val?.name}</h1>
//                       </div>
//                     </SwiperSlide>
//                   ))}
//                   {/* <SwiperSlide>
//                   <div className='main-div'>
//                     <h1 className='title'>Find a space.</h1>
//                     <h1 className='title'>Fulfill your vision.</h1>
//                   </div>
//                 </SwiperSlide>

//                 <SwiperSlide>
//                   <div className='main-div'>
//                     <h1 className='title'>Find a space.</h1>
//                     <h1 className='title'>Fulfill your vision.</h1>
//                   </div>
//                 </SwiperSlide>

//                 <SwiperSlide>
//                   <div className='main-div'>
//                     <h1 className='title'>Find a space.</h1>
//                     <h1 className='title'>Fulfill your vision.</h1>
//                   </div>
//                 </SwiperSlide>*/}
//                 </Swiper>
//               </div>
//             </div>
//           </div>
//           <div className="col-md-6">
//             <div className="sec-2">
//               {/* <img
//               className="ban-img"
//               src={
//                 process.env.PUBLIC_URL +
//                 "/assets/images/homepage/home-banner/bg2.png"
//               }
//             /> */}

//               <Swiper
//                 spaceBetween={0}
//                 effect={"fade"}
//                 modules={[EffectFade, Autoplay]}
//                 className="mySwiper"
//               >
//                 <SwiperSlide>
//                   <img
//                     className="ban-img"
//                     src={
//                       process.env.PUBLIC_URL +
//                       "/assets/images/homepage/home-banner/bg2.png"
//                     }
//                   />
//                 </SwiperSlide>

//                 <SwiperSlide>
//                   <img
//                     className="ban-img"
//                     src={
//                       process.env.PUBLIC_URL +
//                       "/assets/images/homepage/home-banner/bg2.png"
//                     }
//                   />
//                 </SwiperSlide>

//                 <SwiperSlide>
//                   <img
//                     className="ban-img"
//                     src={
//                       process.env.PUBLIC_URL +
//                       "/assets/images/homepage/home-banner/bg2.png"
//                     }
//                   />
//                 </SwiperSlide>

//                 <SwiperSlide>
//                   <img
//                     className="ban-img"
//                     src={
//                       process.env.PUBLIC_URL +
//                       "/assets/images/homepage/home-banner/bg2.png"
//                     }
//                   />
//                 </SwiperSlide>
//               </Swiper>

//               {/* <Swiper
//               spaceBetween={0}
//               effect={"fade"}
//               navigation={true}
//               pagination={{
//                 clickable: true,
//               }}
//               modules={[EffectFade, Navigation, Pagination]}
//               className="mySwiper"
//             >
//               <SwiperSlide>
//                 <img
//                   className="ban-img"
//                   src={
//                     process.env.PUBLIC_URL +
//                     "/assets/images/homepage/home-banner/bg2.png"
//                   }
//                 />
//               </SwiperSlide>
//               <SwiperSlide>
//                 <img
//                   className="ban-img"
//                   src={
//                     process.env.PUBLIC_URL +
//                     "/assets/images/homepage/home-banner/bg1.png"
//                   }
//                 />
//               </SwiperSlide>

//               <SwiperSlide>
//                 <img
//                   className="ban-img"
//                   src={
//                     process.env.PUBLIC_URL +
//                     "/assets/images/homepage/home-banner/bg2.png"
//                   }
//                 />
//               </SwiperSlide>
//               <SwiperSlide>
//                 <img
//                   className="ban-img"
//                   src={
//                     process.env.PUBLIC_URL +
//                     "/assets/images/homepage/home-banner/bg1.png"
//                   }
//                 />
//               </SwiperSlide>
//             </Swiper> */}
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }

// export default Banner;
