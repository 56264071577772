import React, { useEffect, useRef, useState, useContext } from "react";
import "../bookings-form.css";
import Daily_breadcrumb from "../daily-bookings/breadcrumb/Daily_breadcrumb";
import Daily_form from "../daily-bookings/daily-form/Daily_form";
import { Context } from "../../../utils/context";
import { mediaSpaceDetails } from "../../../utils/apis/MediaSpace/MediaSpace";
import { useParams } from "react-router-dom";
const Daily_bookings = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);
  const [data, setData] = useState();

  console.log(data, "dddddddddddatatatatatatatata");

  const { id } = useParams();
  const { IMG_URL } = useContext(Context);
  const getAllData = async () => {
    const res = await mediaSpaceDetails(id);
    if (res?.success) {
      setData(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };
  useEffect(() => {
    getAllData();
  }, [id]);
  return (
    <>
      <div className="Hourly_bookings_topbg">
        <div className="container">
          <h1 className="make-yourh1">Make Your Plans a Reality</h1>
          <Daily_breadcrumb data={data} />
          <Daily_form data={data} />
        </div>
      </div>
    </>
  );
};

export default Daily_bookings;
