import React, { useEffect, useRef, useState, useContext } from 'react'
import './about-content.css'
import { Context } from "../../../utils/context";
import { HomeAbout } from "../../../utils/apis/AboutUs/AboutUs";
import parse from "html-react-parser";
const About_content = () => {
    const [data, setData] = useState();
    const { IMG_URL } = useContext(Context);
    const getAllData = async () => {
        const res = await HomeAbout();
        if (res?.success) {
            setData(res?.data);
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {
        getAllData();
    }, []);
    return (
        <>
            <section className='About_content'>
                <div className='container-fluid p-0'>

                    {/* <div className='col-md-10 mx-auto'> */}
                    {/* <div className='background-card'> */}
                    {/* <img className='layer-img' src={process.env.PUBLIC_URL + '/assets/images/about-us/about-content/layer.png'} /> */}

                    <div className='container'>
                        <div className='background-card abt-box-shd'>
                            <img className='curve-img' src={process.env.PUBLIC_URL + '/assets/images/about-us/banner/layer.png'}  />
                            <div className='row'>
                                <div className='col-xl-5 col-md-6 order-md-1 order-2'>
                                    <div className='cont-div'>
                                        <h1 className='Title'>{data?.name}</h1>
                                        <p className='subtitle'>{typeof data?.description === 'string' ? parse(data?.description) : ""}</p>

                                        {/* <p className='content'>At FlicknShoot, we offer top-of-the-line studio spaces for rent, tailored to
                                            meet the needs of creative individuals, content creators, photographers,
                                            videographers, and artists alike. Whether you're working on a photography
                                            project, filming a video, hosting a workshop, or conducting a rehearsal,
                                            our versatile studios provide the perfect environment to bring your vision
                                            to life.</p>

                                        <p className='content'>Step into the realm of FlicknShoot, where imagination meets reality in
                                            our state-of-the-art studio havens. Crafted for the dreamers, the story-
                                            -tellers, and the visionaries, our spaces are a canvas for your creativity.
                                            Photographers, capture the light; videographers, frame your narrative;
                                            artists, paint your stories; and creators, design your dreams. From the
                                            click of a shutter to the final cut, our studios are more than just a space-
                                            they’re the incubators of your next masterpiece. Unleash your potential
                                            within these walls, where every project flourishes and every rehearsal
                                            transforms into performance.</p> */}
                                    </div>
                                </div>
                                <div className='col-xl-7 col-md-6 order-md-2 order-1 '>
                                    {/* <div className='sec2-title'>
                                        <h1 className='abtus'>Abo<span className='Uset'>U</span>t<span className='s-set'>s</span>  </h1>
                                    </div> */}
                                    <div className='about-text-div'>
                                        <img className='text-img' src={process.env.PUBLIC_URL + '/assets/images/about-us/about-content/about-text.png'} />
                                    </div>

                                </div>
                                <div class="curved-shape"></div>
                              

                            </div>

                            <div className='abt-logo-div'>
                                <img className='log' src={process.env.PUBLIC_URL + '/assets/images/about-us/about-content/logo.png'} />
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                    {/* </div> */}

                </div>
            </section>
        </>
    )
}

export default About_content