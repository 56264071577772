import React from 'react'

const Account_banner = () => {
  return (
    <>
        <section className='About_banner_sec'>
            <div className='container-fluid p-0'>
                <img className='banner-img' src={process.env.PUBLIC_URL +'/assets/images/about-us/banner/banner.png'} />
                <div className='overlayblack'></div>
                <div className='overlay-text'>
                    <h3 className='banner-title'>My Space</h3>
                    <h6 className='subtitle'>Account</h6>
                </div>
            </div>
        </section>
    </>
  )
}

export default Account_banner