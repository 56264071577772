import React, { useEffect } from "react";
import About_banner from "./about-banner/About_banner";
import About_content from "./about-content/About_content";
import Meet_team from "./meet-team/Meet_team";
import Our_mission from "./mission-vision/Our_mission";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);
  return (
    <>
      <About_banner />
      <About_content />
      <Meet_team />
      <Our_mission />
    </>
  );
};

export default About;
