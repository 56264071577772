import React, { useEffect, useRef, useState, useContext } from 'react'
import './home-motivated.css'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination ,Navigation } from 'swiper/modules';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { Context } from "../../../utils/context";
import { MotivatedBy } from "../../../utils/apis/Home/Homeapi";
import parse from "html-react-parser";
const Motivated = () => {

    const [swiperInstance, setSwiperInstance] = useState(null);
    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }
    };

    const [data, setData] = useState();
    const { IMG_URL } = useContext(Context);
    const getAllData = async () => {
        const res = await MotivatedBy();
        if (res?.success) {
            setData(res?.data);
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {
        getAllData();
    }, []);
    return (
        <>
            <section className='home-motivated'>
                <div className='container-md'>
                    <h1 className='title'>We are motivated by</h1>

                    <div className='slider-section'>
                        <Swiper
                            slidesPerView={1.5}
                            spaceBetween={70}
                            // navigation={{
                            //     clickable: true,
                            // }}
                            loop={true}
                            pagination={{
                            clickable: true,
                            }}

                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                              }}
                           
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                },
                                768: {
                                    slidesPerView: 1.5,
                                },

                            }}
                            // freeMode={true}
                            // autoplay={{
                            //     delay: 2500,
                            //     disableOnInteraction: false,
                            //   }}
                            // centeredSlides={false}
                            modules={[Autoplay ,Navigation]}
                            className="mySwiper"
                            onSwiper={(swiper) => setSwiperInstance(swiper)}

                        >

                           

                            {data?.map((val, index) =>
                                <SwiperSlide>
                                    <div className='row justify-content-space-between '>

                                        <div className='col-lg-5 col-md-5 col-6 mx-auto'>
                                            <div className='imgSlider_sec '>
                                                <img className='emp1' src={IMG_URL + val?.image} />

                                                <div className='img-bg1'></div>
                                            </div>
                                        </div>
                                        <div className='col-lg-7 col-md-7 col-10 mx-auto mb-5 colhide'>
                                            <img className='symbol-img' src={process.env.PUBLIC_URL + '/assets/images/homepage/motivated/symbol.png'} />

                                            <p className='testimoni-cont'>{ parse(val?.description) }</p>

                                            <p className='name'>{val?.name}</p>
                                            <p className='location'>{val?.city?.name}</p>
                                        </div>
                                    </div>
                                </SwiperSlide>)}

                            

                            <div class="silder-btn">
                                <div
                                    className="back-btn"
                                    onClick={() => handleMainSliderClick("prev")}
                                >
                                    <FontAwesomeIcon icon={faCaretLeft} />
                                </div>
                                <div
                                    className="next-btn"
                                    onClick={() => handleMainSliderClick("next")}
                                >
                                    <FontAwesomeIcon icon={faCaretRight} />
                                </div>
                            </div>


                        </Swiper>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Motivated