import React from "react";
import "../../../studio-inner/Breadcrumb-comp/breadcrumb-comp.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
const Hourly_breadcrumb = ({ data }) => {
  const navigate = useNavigate();
  const handleSelect = (href) => {
    navigate(href);
  };

  console.log(
    data,
    "dddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd"
  );

  return (
    <>
      <div className="container">
        <div className="Breadcrumb_comp">
          <Breadcrumb>
            <Breadcrumb.Item className="firstbread" href="/">
              Home <FontAwesomeIcon icon={faChevronRight} />{" "}
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() =>
                handleSelect(`/media-spaces/${data?.category?.id}`)
              }
            >
              {data?.category?.name} <FontAwesomeIcon icon={faChevronRight} />
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => handleSelect(`/studio-inner/${data?.id}`)}
            >
              {data?.name} <FontAwesomeIcon icon={faChevronRight} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Hourly Booking</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
    </>
  );
};

export default Hourly_breadcrumb;
