import React, { useEffect } from 'react'
import './Subscription.css'
import { Container, Row, Col, Nav, Tab, } from 'react-bootstrap'
import MonthlyCard from './MonthlyCard/MonthlyCard'
import Subscription_banner from './subscription-banner/Subscription_banner'
const Subscription = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when component mounts
    }, []);
    return (
        <>
            <section className='subscription'>
                <Subscription_banner />

                <Container>


                    <div className='background-card med-top'>
                        <img className='curve-img' src={process.env.PUBLIC_URL + '/assets/images/about-us/banner/layer.png'} />
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row>
                                <Col xxl={2} xl={3} lg={3} md={4} sm={5} className='mx-auto '>
                                    <Nav variant="pills" className="justify-content-center ">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first">Monthly</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second">Annual</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Row className='justify-content-center'>
                                            <Col xxl={11} xl={11} lg={12} >
                                                <Row>
                                                    <Col lg={4} md={6} sm={6}>
                                                        <MonthlyCard
                                                            image={process.env.PUBLIC_URL + '/assets/images/subscription/plan-image1.png'}
                                                            content="Starter Plan"
                                                            RupeesText="₹ 999"
                                                        />

                                                    </Col>
                                                    <Col lg={4} md={6} sm={6}>
                                                        <MonthlyCard
                                                            image={process.env.PUBLIC_URL + '/assets/images/subscription/plan-image1.png'}
                                                            content="Optimal Plan"
                                                            RupeesText="₹ 999"
                                                        />
                                                    </Col>
                                                    <Col lg={4} md={6} sm={6}>
                                                        <MonthlyCard
                                                            image={process.env.PUBLIC_URL + '/assets/images/subscription/plan-image1.png'}
                                                            content="Premium Plan"
                                                            RupeesText="₹ 999"
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <Row className='justify-content-center'>
                                            <Col xxl={11} xl={11} lg={12} >
                                                <Row>
                                                    <Col lg={4} md={6} sm={6}>
                                                        <MonthlyCard
                                                            image={process.env.PUBLIC_URL + '/assets/images/subscription/plan-image1.png'}
                                                            content="Starter Plan"
                                                            RupeesText="₹ 99"
                                                        />

                                                    </Col>
                                                    <Col lg={4} md={6} sm={6}>
                                                        <MonthlyCard
                                                            image={process.env.PUBLIC_URL + '/assets/images/subscription/plan-image1.png'}
                                                            content="Optimal Plan"
                                                            RupeesText="₹ 99"
                                                        />
                                                    </Col>
                                                    <Col lg={4} md={6} sm={6}>
                                                        <MonthlyCard
                                                            image={process.env.PUBLIC_URL + '/assets/images/subscription/plan-image1.png'}
                                                            content="Premium Plan"
                                                            RupeesText="₹ 99"
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Tab.Pane>
                                </Tab.Content>

                            </Row>
                        </Tab.Container>
                    </div>

                </Container>
            </section>
        </>
    )
}

export default Subscription
