import React, { useEffect, useRef, useState, useContext } from "react";
import "./Studio_card_one.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import Book_studio_modal from "../../common-section/Modals/book-studio-modal/Book_studio_modal";
import parse from "html-react-parser";
import { Context } from "../../../utils/context";
import { useNavigate } from "react-router-dom";
const Studio_card_one = ({ data }) => {
  const navigate = useNavigate();
  const [modalShow, setModalShow] = React.useState(false);
  const { IMG_URL, signin } = useContext(Context);
  const handleBookClick = () => {
    if (signin) {
      setModalShow(true);
    } else {
      navigate("/login"); // Redirect to the login page
    }
  };

  const ratings = data?.studio_reviews?.map((rate) => rate?.ratings);

  // Calculate the sum of all ratings
  const totalRatings = ratings?.reduce((sum, rating) => sum + rating, 0);

  // Calculate the average by dividing the total by the number of ratings
  const averageRating =
    ratings && ratings.length > 0 ? totalRatings / ratings.length : 0;

  return (
    <>
      <section className="Studio_card_one">
        <div className="container">
          <div className="row card-bgg">
            {/* <div className=''></div> */}
            <div className="col-xxl-7 col-lg-6 p-4">
              <div className="sec-1">
                <h1 className="stud-one">
                  {data?.name} <span>{data?.address}</span>
                </h1>
                <p className="starp">
                  {averageRating}{" "}
                  <FontAwesomeIcon className="staricon" icon={faStar} />
                </p>

                <p className="contett">
                  Capacity Of people :- {data?.studio_crew_capacity}
                </p>

                <p className="contett">
                  {typeof data?.description === "string"
                    ? parse(data?.description)
                    : ""}
                </p>

                <div className="d-flex">
                  <button
                    className="book-btn"
                    type="button"
                    onClick={handleBookClick}
                  >
                    Book
                  </button>
                  <div className="my-auto">
                    <p className="startinfp">
                      Starting from: ₹ {data?.hourly_basis_charges}/Hr
                    </p>
                  </div>
                </div>

                <Book_studio_modal
                  name={data?.name}
                  studio_id={data?.id}
                  image={data?.studio_images[0]?.images}
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
              </div>
            </div>
            <div className="col-xxl-5 col-lg-6 p-4">
              <img
                className="stud-img"
                src={IMG_URL + data?.studio_images[0]?.images}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Studio_card_one;
