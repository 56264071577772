import React, { useEffect, useRef, useState, useContext } from "react";
import "./Account.css";
import { Col, Container, Form, Nav, Row, Tab } from "react-bootstrap";
import {
  faCalendarDays,
  faClock,
  faEyeSlash,
  faEye,
  faFileLines,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import {
  faListCheck,
  faMagnifyingGlass,
  faSquareCheck,
  faTableCellsLarge,
  faTicket,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import Account_banner from "./account-banner/Account_banner";
import Book_cancel_modal from "../common-section/Modals/book-cancel-modal/Book_cancel_modal";
import Historycard_view_modal from "../common-section/Modals/historycard-view-modal/Historycard_view_modal";
import Success_modal from "../common-section/Modals/Success_modal/Success_modal";
import Add_review_modal from "../common-section/Modals/add-review-modal/Add_review_modal";
import { Context } from "../../utils/context";
import {
  ProfileGet,
  EditProfile,
  getBooking,
  cancelBooking,
  getBookingHistory,
} from "../../utils/apis/myAccount/myAccount";
import { RegxExpression } from "../../utils/common";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import PasswordChecklist from "react-password-checklist";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import parse from "html-react-parser";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import { faHistory } from "@fortawesome/free-solid-svg-icons";
import { faReceipt } from "@fortawesome/free-solid-svg-icons";
// import { userRegister } from "../../utils/apis/login/Login";
function Account() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  const [phoneValue, setPhoneValue] = useState("");

  const [modalShow, setModalShow] = React.useState(false);

  const [modalShow1, setModalShow1] = React.useState(false);

  const [modalShow2, setModalShow2] = useState(false);

  const [modalShow3, setModalShow3] = useState(false);

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  // const togglePasswordVisibility = () => {
  //     setPasswordVisible(!passwordVisible);
  // };
  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const [passwordVisible2, setPasswordVisible2] = useState(false);
  const [confirmPasswordVisible2, setConfirmPasswordVisible2] = useState(false);
  const togglePasswordVisibility2 = () => {
    setPasswordVisible2(!passwordVisible2);
  };
  const toggleConfirmPasswordVisibility2 = () => {
    setConfirmPasswordVisible2(!confirmPasswordVisible2);
  };

  const [passwordVisible3, setPasswordVisible3] = useState(false);
  const [confirmPasswordVisible3, setConfirmPasswordVisible3] = useState(false);
  const togglePasswordVisibility3 = () => {
    setPasswordVisible3(!passwordVisible3);
  };
  const toggleConfirmPasswordVisibility3 = () => {
    setConfirmPasswordVisible3(!confirmPasswordVisible3);
  };
  const [data, setData] = useState();
  const [dataHistory, setDataHistory] = useState();

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordOne, setShowPasswordOne] = useState(false);
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [iconOne, setIconOne] = useState(faEyeSlash);
  const [iconTwo, setIconTwo] = useState(faEyeSlash);
  const [iconNew, setIconNew] = useState(faEyeSlash);
  const navigate = useNavigate();

  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
      setIconOne(showPassword ? faEyeSlash : faEye);
    } else if (field === "new_password") {
      setShowPasswordNew(!showPasswordNew);
      setIconNew(showPasswordOne ? faEyeSlash : faEye);
    } else if (field === "reenterPassword") {
      setShowPasswordOne(!showPasswordOne);
      setIconTwo(showPasswordOne ? faEyeSlash : faEye);
    }
  };

  const { signin, setSignin, shimmerLoader, setShimmerLoader } = useContext(
    Context
  );

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  const onSubmit = async (data) => {
    setShimmerLoader(true);
    if (password === passwordAgain) {
      const res = await EditProfile(data);
      if (res?.success) {
        reset();
        setModalShow2(true);
        getAllData();
      } else {
        setShimmerLoader(false);

        if (res?.message) {
          setError("current_password", {
            type: "manual",
            message: res.message?.message,
          });
        }
        // Handle errors
        if (res?.message?.email) {
          setError("email", { type: "manual", message: res.message.email });
        }
        if (res?.message?.contact_no) {
          setError("contact_no", {
            type: "manual",
            message: res.message.contact_no,
          });
        }
      }
    } else {
      setError("re_password", { message: "Password Does not match" });
    }
  };
  const [selectedTimeframe, setSelectedTimeframe] = useState("");
  const [password, setPassword] = useState("");
  const [studioId, setStudioId] = useState(null);
  const [passwordAgain, setPasswordAgain] = useState("");
  const { IMG_URL } = useContext(Context);
  const getAllData = async () => {
    const res = await ProfileGet();
    if (res?.success) {
      reset(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };
  const getAllBookingData = async (selectedTimeframe) => {
    const res = await getBooking(selectedTimeframe, "", "Schedule");
    if (res?.success) {
      setData(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const getAllBookingDataHistory = async (selectedTimeframe, term) => {
    const res = await getBookingHistory(selectedTimeframe, term, "Complied");
    if (res?.success) {
      setDataHistory(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const [cancelId, setcancelId] = useState("");
  const cancelResonse = async (id) => {
    setcancelId(id);
    setModalShow(true);
  };
  const [ADDId, setAddId] = useState("");
  const handelAddON = async (id) => {
    setAddId(id);
    setModalShow1(true);
  };

  useEffect(() => {
    getAllData();
    getAllBookingDataHistory();
    getAllBookingData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = date
      .toLocaleString("default", { month: "short" })
      .toUpperCase();
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  };
  const formatTimeToAMPM = (time) => {
    // Split time by periods (it expects "HH.MM.SS" but handles missing parts)
    const parts = time.split(".");

    // Ensure hours and minutes exist, set defaults if not
    const hours = parts[0] || "00";
    const minutes = parts[1] || "00"; // Default to "00" if minutes are missing

    let hour = parseInt(hours, 10);
    let period = "AM";

    if (hour >= 12) {
      period = "PM";
      if (hour > 12) {
        hour -= 12; // Convert to 12-hour format
      }
    } else if (hour === 0) {
      hour = 12; // Handle midnight case
    }

    return `${hour}:${String(minutes).padStart(2, "0")} ${period}`;
  };

  const handleRadioChange = (e) => {
    setSelectedTimeframe(e.target.value);
    getAllBookingData(e.target.value);
  };
  const handleRadioChangeHistory = (e) => {
    setSelectedTimeframe(e.target.value);
    getAllBookingDataHistory(e.target.value);
  };
  const handleInputChange = (e) => {
    getAllBookingDataHistory("", e.target.value);
  };

  const handleRating = (id) => {
    setStudioId(id);
    setModalShow3(true);
  };

  const handleDownload = async (invoice) => {
    try {
      // const response = await getData(`/order/invoice-download/${id}`);

      const filePath = invoice;

      if (!filePath) {
        console.error("File path is undefined or null");
        return;
      }

      const url = `${IMG_URL}/${filePath}`;

      // Create an invisible link element and open the file in a new tab
      const a = document.createElement("a");
      a.href = url;
      a.target = "_blank"; // Open in a new tab
      a.rel = "noopener noreferrer"; // Recommended for security reasons
      a.click();
    } catch (error) {
      console.error("Error opening file:", error);
    }
  };

  const formatTimeTo12Hour = (time) => {
    const [hours, minutes] = time.split(":");
    const period = +hours >= 12 ? "PM" : "AM";
    const formattedHours = +hours % 12 || 12; // Convert 24-hour to 12-hour format
    return `${formattedHours}:${minutes} ${period}`;
  };

  return (
    <>
      <section className="account_section">
        <Account_banner />

        <Container>
          <div className="account_main_div">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row>
                <Col xxl={4} xl={4} lg={4} md={12} sm={12} className="mb-4">
                  <div className="acctab_div mb-4">
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link eventKey="first">
                          {" "}
                          <FontAwesomeIcon
                            icon={faUser}
                            className="me-2"
                          />{" "}
                          Personal Details
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">
                          {" "}
                          <FontAwesomeIcon
                            icon={faCalendarCheck}
                            className="me-2"
                          />{" "}
                          Bookings
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">
                          {" "}
                          <FontAwesomeIcon
                            icon={faHistory}
                            onClick={getAllBookingDataHistory}
                            className="me-2"
                          />{" "}
                          History
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="fourth">
                          {" "}
                          <FontAwesomeIcon
                            icon={faReceipt}
                            className="me-2"
                          />{" "}
                          Subscription
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="fiveth">
                          {" "}
                          <FontAwesomeIcon
                            icon={faWallet}
                            className="me-2"
                          />{" "}
                          Payment Method
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  <div className="acctab_div">
                    <Tab.Content>
                      <Tab.Pane eventKey="second">
                        <p className="sorttext">Sort by</p>
                        <div className="shorttabss py-2">
                          <Form>
                            <div className="checcckboxx">
                              <Form.Check
                                type="radio"
                                label="All"
                                name="timeframe"
                                id=" "
                                value=""
                                checked={selectedTimeframe === ""}
                                onChange={handleRadioChange}
                              />
                            </div>
                            <div className="checcckboxx">
                              <Form.Check
                                type="radio"
                                label="Daily"
                                name="timeframe"
                                id="Daily"
                                value="Daily"
                                checked={selectedTimeframe === "Daily"}
                                onChange={handleRadioChange}
                              />
                            </div>
                            <div className="checcckboxx">
                              <Form.Check
                                type="radio"
                                label="Hourly"
                                name="timeframe"
                                id="Hourly"
                                value="Hourly"
                                checked={selectedTimeframe === "Hourly"}
                                onChange={handleRadioChange}
                              />
                            </div>
                          </Form>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <p className="sorttext">Sort by</p>
                        <div className="shorttabss py-2">
                          <Form>
                            <div className="checcckboxx">
                              <Form.Check
                                type="radio"
                                label="All"
                                name="timeframe"
                                id=" "
                                value=""
                                checked={selectedTimeframe === ""}
                                onChange={handleRadioChangeHistory}
                              />
                            </div>
                            <div className="checcckboxx">
                              <Form.Check
                                type="radio"
                                label="Daily"
                                name="timeframe"
                                id="Daily"
                                value="Daily"
                                checked={selectedTimeframe === "Daily"}
                                onChange={handleRadioChangeHistory}
                              />
                            </div>
                            <div className="checcckboxx">
                              <Form.Check
                                type="radio"
                                label="Hourly"
                                name="timeframe"
                                id="Hourly"
                                value="Hourly"
                                checked={selectedTimeframe === "Hourly"}
                                onChange={handleRadioChangeHistory}
                              />
                            </div>
                          </Form>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Col>
                <Col xxl={8} xl={8} lg={8} md={12} sm={12} className="mb-4">
                  <div className="acctabcont_div">
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <div className="tab1_div">
                          <Form className="row">
                            <Form.Group
                              className="mb-3 col-md-6 col-sm-6 col-12"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                First Name<span>*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="First_Name"
                                placeholder="Enter First Name"
                                {...register("first_name", {
                                  required: "First name required",
                                })}
                                className={classNames("", {
                                  "is-invalid": errors?.first_name,
                                  "is-valid": getValues("first_name"),
                                })}
                                onKeyDown={(event) => {
                                  if (!RegxExpression.name.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </Form.Group>

                            <Form.Group
                              className="mb-3 col-md-6 col-sm-6 col-12"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                Last Name<span>*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="last_name"
                                placeholder="Enter Last Name"
                                {...register("last_name", {
                                  required: "last name required",
                                })}
                                className={classNames("", {
                                  "is-invalid": errors?.last_name,
                                  "is-valid": getValues("last_name"),
                                })}
                                // onKeyDown={(event) => {
                                //     if (!RegxExpression.name.test(event.key)) {
                                //         event.preventDefault();
                                //     }
                                // }}
                              />
                            </Form.Group>

                            <Form.Group
                              className="mb-3 col-12"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                Display Name<span>*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="display_name"
                                placeholder="Enter Display Name"
                                {...register("display_name", {
                                  required: "display_name required",
                                })}
                                className={classNames("", {
                                  "is-invalid": errors?.display_name,
                                  "is-valid": getValues("display_name"),
                                })}
                                // onKeyDown={(event) => {
                                //     if (!RegxExpression.name.test(event.key)) {
                                //         event.preventDefault();
                                //     }
                                // }}
                              />
                              <p className="displa-instru">
                                This will be how your name will be displayed in
                                the account section and in reviews
                              </p>
                            </Form.Group>

                            <div>
                              <Form.Label>
                                Contact<span>*</span>
                              </Form.Label>

                              <Controller
                                name="contact_no"
                                control={control}
                                rules={{
                                  required: "contact_no is required",
                                  minLength: {
                                    value: 10,
                                    message:
                                      "Number should be at least 10 characters",
                                  },
                                }}
                                render={({ field }) => (
                                  <PhoneInput
                                    {...field}
                                    className={classNames("", {
                                      "is-invalid": errors?.contact_no,
                                      "is-valid": getValues("contact_no"),
                                    })}
                                    onChange={(value) => field.onChange(value)}
                                    type="text"
                                    placeholder="Enter Mobile Number"
                                    onKeyDown={(event) => {
                                      if (
                                        !RegxExpression.phone.test(event.key)
                                      ) {
                                        if (event.key !== "Backspace") {
                                          event.preventDefault();
                                        }
                                      }
                                    }}
                                    maxLength={10}
                                  />
                                )}
                              />
                              {errors?.contact_no && (
                                <sup className="text-danger">
                                  {errors?.contact_no?.message}
                                </sup>
                              )}
                            </div>

                            <Form.Group
                              className="mb-3 col-12"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                Email address<span>*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="email"
                                placeholder="Enter Email"
                                {...register("email", {
                                  required: "Email is required",
                                  pattern: {
                                    value: RegxExpression.email,
                                    message: "Invalid email address",
                                  },
                                })}
                                className={classNames("", {
                                  "is-invalid": errors?.email,
                                  "is-valid": getValues("email"),
                                })}
                              />
                              {errors?.email && (
                                <sup className="text-danger">
                                  {errors?.email?.message}
                                </sup>
                              )}
                            </Form.Group>

                            <Form.Group
                              className="mb-3 col-12"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                Current Password
                                <span className="smalllabel">
                                  {" "}
                                  (leave blank to leave unchanged)
                                </span>
                              </Form.Label>
                              <div className="eyediv">
                                <Form.Control
                                  type={showPassword ? "text" : "password"}
                                  placeholder="Enter Password*"
                                  name="current_password"
                                  {...register("current_password", {
                                    // required: "Password is required",
                                    minLength: {
                                      value: 8,
                                      message:
                                        "Password must be at least 8 characters long",
                                    },
                                    pattern: {
                                      value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                                      message:
                                        "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                                    },
                                  })}
                                />
                                {errors?.current_password && (
                                  <sup className="text-danger">
                                    {errors?.current_password?.message}
                                  </sup>
                                )}
                                <div className="eye-icon-holder">
                                  <FontAwesomeIcon
                                    icon={iconOne}
                                    className="eyeicn"
                                    onClick={() =>
                                      togglePasswordVisibility("password")
                                    }
                                  />
                                </div>
                              </div>
                            </Form.Group>

                            <Form.Group
                              className="mb-3 col-12"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                New Password{" "}
                                <span className="smalllabel">
                                  {" "}
                                  (leave blank to leave unchanged)
                                </span>
                              </Form.Label>
                              <div className="eyediv">
                                <Form.Control
                                  type={showPasswordNew ? "text" : "password"}
                                  placeholder="Enter Password*"
                                  name="new_password"
                                  {...register("new_password", {
                                    // required: "new_password is required",
                                    // minLength: {
                                    //     value: 8,
                                    //     message:
                                    //         "Password must be at least 8 characters long",
                                    // },
                                    // pattern: {
                                    //     value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                                    //     message:
                                    //         "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                                    // },
                                  })}
                                  onChange={(e) => setPassword(e.target.value)}
                                />
                                <div className="eye-icon-holder">
                                  <FontAwesomeIcon
                                    icon={iconNew}
                                    className="eyeicn"
                                    onClick={() =>
                                      togglePasswordVisibility("new_password")
                                    }
                                  />
                                </div>
                              </div>
                            </Form.Group>

                            <Form.Group
                              className="mb-3 col-12"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Confirm Password</Form.Label>
                              <div className="eyediv">
                                <Form.Control
                                  type={showPasswordOne ? "text" : "password"}
                                  placeholder="Re-Enter Password*"
                                  name="re_password"
                                  // {...register("re_password", {
                                  //     required: "Re-Password is required",
                                  // })}
                                  onChange={(e) =>
                                    setPasswordAgain(e.target.value)
                                  }
                                />
                                <div className="eye-icon-holder">
                                  <FontAwesomeIcon
                                    icon={iconTwo}
                                    className="eyeicn"
                                    onClick={() =>
                                      togglePasswordVisibility(
                                        "reenterPassword"
                                      )
                                    }
                                  />
                                </div>
                                {errors?.re_password && (
                                  <sup className="text-danger">
                                    {errors?.re_password?.message}
                                  </sup>
                                )}
                                {password && (
                                  <PasswordChecklist
                                    rules={[
                                      "minLength",
                                      "specialChar",
                                      "number",
                                      "capital",
                                      "match",
                                    ]}
                                    minLength={8}
                                    value={password}
                                    valueAgain={passwordAgain}
                                    onChange={(isValid) => {}}
                                    style={{
                                      fontSize: "10px",
                                      padding: "4px",
                                      margin: "0",
                                    }}
                                  />
                                )}
                              </div>
                            </Form.Group>
                          </Form>
                          <div>
                            {/* <button className='btnnsub mt-3' type='submit' onClick={() => setModalShow2(true)} >SAVE CHANGES</button> */}
                            <button
                              className="btnnsub mt-3"
                              onClick={handleSubmit(onSubmit)}
                            >
                              SAVE CHANGES
                            </button>
                          </div>
                        </div>
                      </Tab.Pane>

                      <Tab.Pane eventKey="second">
                        <div>
                          {data?.length > 0 ? (
                            data.map((val, index) => (
                              <div className="bookiingtab_div">
                                <Row>
                                  <Col
                                    xxl={3}
                                    xl={3}
                                    lg={4}
                                    md={4}
                                    sm={4}
                                    className="pe-sm-0"
                                  >
                                    <div className="imgsidemain">
                                      <div className=" imggsssdiv">
                                        <img
                                          className="imgg"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/images/logo/logo.png"
                                          }
                                          alt="Logo"
                                        />
                                      </div>
                                      <div className="imggtxxxt pt-2">
                                        <p className="bookprice mb-0">
                                          Booking: ₹ {val?.amount}/-
                                        </p>
                                        <p className="adrtext">
                                          (Starting from: ₹{" "}
                                          {val?.studio?.hourly_basis_charges}
                                          /Hr)
                                        </p>
                                        <p
                                          className="adrtext mb-0"
                                          onClick={() => cancelResonse(val?.id)}
                                          type="button"
                                        >
                                          {" "}
                                          <FontAwesomeIcon
                                            icon={faCalendarDays}
                                          />
                                          CANCEL
                                        </p>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col
                                    xxl={9}
                                    xl={9}
                                    lg={8}
                                    md={8}
                                    sm={8}
                                    className="ps-sm-0"
                                  >
                                    <div className="booktext_div">
                                      <div className="card-heading-holder">
                                        <div>
                                          <p className="stdtext mb-1">
                                            {val?.studio?.name}
                                          </p>
                                        </div>

                                        <div>
                                          <p className="timee time2 mb-1">
                                            {" "}
                                            <FontAwesomeIcon
                                              icon={faTicket}
                                              className="me-1"
                                            />{" "}
                                            Status: {val?.stage}
                                          </p>
                                        </div>
                                      </div>

                                      <p className="adrtext mb-1">
                                        {val?.studio?.address}
                                      </p>

                                      <p className="timee time2 mb-1">
                                        {" "}
                                        <FontAwesomeIcon
                                          icon={faTicket}
                                          className="me-1"
                                        />{" "}
                                        Booking: {val?.type}
                                      </p>

                                      <div className="d-flex justify-content-between flex-wrap">
                                        <p className="timee">
                                          <FontAwesomeIcon
                                            icon={faCalendarDays}
                                          />{" "}
                                          {val?.date ? (
                                            <>
                                              <span className="datespan me-1 p-1">
                                                {formatDate(val.date)}
                                              </span>
                                            </>
                                          ) : (
                                            <>
                                              <span className="datespan me-1 p-1">
                                                {formatDate(val?.start_date)}
                                              </span>{" "}
                                              to
                                              <span className="datespan ms-1 p-1">
                                                {formatDate(val?.end_date)}
                                              </span>
                                            </>
                                          )}
                                        </p>

                                        {/* <p className='timee '><FontAwesomeIcon icon={faCalendarDays} /> <span className='datespan me-1 p-1'>{val?.start_date}</span> to  <span className='datespan ms-1 p-1'> {val?.end_date} </span></p> */}
                                        <p className="timee">
                                          <FontAwesomeIcon icon={faClock} />{" "}
                                          <span className="datespan me-1 p-1">
                                            {formatTimeTo12Hour(
                                              val?.start_time
                                            )}{" "}
                                          </span>{" "}
                                          to{" "}
                                          <span className="datespan ms-1 p-1">
                                            {formatTimeTo12Hour(val?.end_time)}
                                          </span>
                                        </p>
                                      </div>

                                      <p className="timee time2 mb-1">
                                        {" "}
                                        <FontAwesomeIcon
                                          icon={faTableCellsLarge}
                                          className="me-1"
                                        />{" "}
                                        Add Ons:
                                      </p>
                                      {val?.booking_add_ons?.map((val1) => (
                                        <p className="adrtext mb-1 ps-3 ms-1">
                                          {val1?.studio_add_on?.add_on?.name} +₹
                                          {val1?.studio_add_on?.rate}/hr
                                        </p>
                                      ))}
                                      <p className="adrtext mb-1 ps-3 ms-1">
                                        Crew
                                      </p>
                                      <p className="timee time2 mb-1">
                                        {" "}
                                        <FontAwesomeIcon
                                          icon={faListCheck}
                                          className="me-1"
                                        />{" "}
                                        Description:
                                      </p>
                                      <div className="d-flex justify-content-between flex-wrap">
                                        <p className="adrtext mb-0 ps-3 ms-1">
                                          {val?.basic_information?.description}
                                        </p>
                                        <p
                                          className="timee time2  text-decoration-underline mb-0"
                                          onClick={() =>
                                            handleDownload(val?.invoice)
                                          }
                                        >
                                          View More
                                        </p>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            ))
                          ) : (
                            <div
                              className="text-center"
                              style={{
                                fontWeight: "bold",
                                padding: "20px",
                                color: "white",
                              }}
                            >
                              No Data Available
                            </div>
                          )}

                          {/* <div className='bookiingtab_div'>
                                                        <Row>
                                                            <Col xxl={3} xl={3} lg={4} md={4} sm={4} className='pe-sm-0'>
                                                                <div className='imgsidemain'>
                                                                    <div className=' imggsssdiv'>
                                                                        <img
                                                                            className="imgg"
                                                                            src={
                                                                                process.env.PUBLIC_URL + "/assets/images/logo/logo.png"
                                                                            }
                                                                            alt="Logo"
                                                                        />

                                                                    </div>
                                                                    <div className='imggtxxxt pt-2'>
                                                                        <p className='bookprice mb-0'>Booking: ₹ 4,200/-</p>
                                                                        <p className='adrtext'>(Starting from: ₹ 700/Hr)</p>
                                                                        <p className='adrtext mb-0' onClick={() => setModalShow(true)} type="button"> <FontAwesomeIcon icon={faCalendarDays} />CANCEL</p>
                                                                    </div>
                                                                </div>

                                                            </Col>
                                                            <Col xxl={9} xl={9} lg={8} md={8} sm={8} className='ps-sm-0'>
                                                                <div className='booktext_div'>

                                                                    <p className='stdtext mb-1'>Studio One</p>
                                                                    <p className='adrtext mb-1'>S.B. Road, Pune</p>
                                                                    <p className='timee time2 mb-1'> <FontAwesomeIcon icon={faTicket} className='me-1' /> Booking: Daily</p>
                                                                    <div className='d-flex justify-content-between flex-wrap'>
                                                                        <p className='timee '><FontAwesomeIcon icon={faCalendarDays} /> <span className='datespan me-1 p-1'>30.JUN.2024 </span> to  <span className='datespan ms-1 p-1'> 20.JUL.2024 </span></p>
                                                                        <p className='timee '><FontAwesomeIcon icon={faClock} /> <span className='datespan me-1 p-1'> 10:00am </span> to <span className='datespan ms-1 p-1'> 04:00pm </span></p>
                                                                    </div>

                                                                    <p className='timee time2 mb-1'> <FontAwesomeIcon icon={faTableCellsLarge} className='me-1' /> Add Ons:</p>
                                                                    <p className='adrtext mb-1 ps-3 ms-1'>Professional Assistant +₹60/hr</p>
                                                                    <p className='adrtext mb-1 ps-3 ms-1'>Crew</p>
                                                                    <p className='timee time2 mb-1'> <FontAwesomeIcon icon={faListCheck} className='me-1' /> Description:</p>
                                                                    <div className='d-flex justify-content-between flex-wrap'>
                                                                        <p className='adrtext mb-0 ps-3 ms-1'>Lorem Ipsum is simply dummy text of the printing & typesetting...</p>
                                                                        <p className='timee time2  text-decoration-underline mb-0'>View More</p>
                                                                    </div>
                                                                </div>

                                                            </Col>
                                                        </Row>
                                                    </div> */}
                        </div>
                      </Tab.Pane>

                      <Tab.Pane eventKey="third">
                        <div className="tab2div">
                          <Form.Group
                            className="mb-5 col-12"
                            controlId="exampleForm.ControlInput1"
                          >
                            <div className="eyediv">
                              <Form.Control
                                type="text"
                                placeholder="Search by Date / Studio Name"
                                // value={searchTerm}
                                onChange={handleInputChange}
                              />
                              <FontAwesomeIcon
                                icon={faMagnifyingGlass}
                                className="eyeicn2"
                              />
                            </div>
                          </Form.Group>

                          {dataHistory?.length > 0 ? (
                            dataHistory?.map((val, index) => (
                              <div className="tabbcard_div">
                                <div className="crdd1_div">
                                  <div className="imgrowdivv">
                                    <div className=" imggdiv">
                                      <img
                                        className="imgg"
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/logo/logo.png"
                                        }
                                        alt="Logo"
                                      />
                                    </div>
                                    <div className="bookd_div">
                                      <p className="booktext">
                                        Booked on:{" "}
                                        {val?.createdAt
                                          ? formatDate(val.createdAt)
                                          : "N/A"}
                                      </p>
                                    </div>
                                  </div>
                                  <div className=" texttdiv">
                                    <p className="stdtext mb-1">
                                      {val?.studio?.name}
                                    </p>
                                    <p className="adrtext mb-1">
                                      {val?.studio?.address}
                                    </p>
                                    <p className="timee time2 mb-1">
                                      Booking: {val?.type} (₹ {val?.amount}/-)
                                    </p>
                                    <div className="d-flex justify-content-between">
                                      <p className="timee mb-1">
                                        <FontAwesomeIcon
                                          icon={faCalendarDays}
                                        />
                                        {val?.date ? (
                                          val.date
                                        ) : (
                                          <>
                                            {formatDate(val?.start_date)} to{" "}
                                            {formatDate(val?.end_date)}
                                          </>
                                        )}
                                      </p>
                                      <p className="timee mb-1">
                                        <FontAwesomeIcon icon={faClock} />{" "}
                                        {formatTimeToAMPM(val.start_time)} to{" "}
                                        {formatTimeToAMPM(val.end_time)}
                                      </p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      {val?.studio?.studio_reviews?.length <
                                      0 ? (
                                        <p
                                          className="timee time2 mb-0"
                                          onClick={() =>
                                            handleRating(val?.studio?.id)
                                          }
                                        >
                                          Add Ratings & Review
                                        </p>
                                      ) : (
                                        <></>
                                      )}

                                      <p
                                        className="timee time2  text-decoration-underline mb-0"
                                        onClick={() => handelAddON(val?.id)}
                                      >
                                        View More
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="">
                                  {val?.Booking_status === "Book" ? (
                                    val?.stage === "Accepted" ? (
                                      <div className="complettext">
                                        <p>COMPLETE</p>
                                      </div>
                                    ) : (
                                      <p>{val?.stage}</p>
                                    )
                                  ) : (
                                    <div className="cancledtext">
                                      <p>CANCELED</p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ))
                          ) : (
                            <div
                              className="text-center"
                              style={{
                                fontWeight: "bold",
                                padding: "20px",
                                color: "white",
                              }}
                            >
                              No Data Available
                            </div>
                          )}
                        </div>
                      </Tab.Pane>

                      <Tab.Pane eventKey="fourth">
                        <div>
                          <div className="bookiingtab_div">
                            <Row>
                              <Col
                                xxl={3}
                                xl={3}
                                lg={4}
                                md={4}
                                sm={4}
                                xs={4}
                                className="pe-0"
                              >
                                <div className="imgsidemain forrdivv">
                                  <div className=" imggsssdiv">
                                    <img
                                      className="imgg"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/logo/logo.png"
                                      }
                                      alt="Logo"
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col
                                xxl={9}
                                xl={9}
                                lg={8}
                                md={8}
                                sm={8}
                                xs={8}
                                className="ps-0"
                              >
                                <div className="booktext_div subscrtabdiv">
                                  <p className="stdtext mb-1">
                                    Starter Plan (₹99)
                                  </p>
                                  <p className="adrtext  mb-1">
                                    {" "}
                                    <FontAwesomeIcon
                                      icon={faSquareCheck}
                                      className="squrecheckiccn me-1"
                                    />{" "}
                                    Lorem Ipsum Dolor Sit Amet
                                  </p>
                                  <p className="adrtext  mb-1">
                                    {" "}
                                    <FontAwesomeIcon
                                      icon={faSquareCheck}
                                      className="squrecheckiccn me-1"
                                    />{" "}
                                    Lorem Ipsum Dolor Sit Amet
                                  </p>
                                  <p className="adrtext  mb-1">
                                    {" "}
                                    <FontAwesomeIcon
                                      icon={faSquareCheck}
                                      className="squrecheckiccn me-1"
                                    />{" "}
                                    Lorem Ipsum Dolor Sit Amet
                                  </p>
                                  <p className="adrtext  mb-1">
                                    {" "}
                                    <FontAwesomeIcon
                                      icon={faSquareCheck}
                                      className="squrecheckiccn me-1"
                                    />{" "}
                                    Lorem Ipsum Dolor Sit Amet
                                  </p>

                                  <div className=" update_div">
                                    <p>Update</p>
                                  </div>
                                  <div className="monthly_div">
                                    <h3 className="monthly_p">Monthly</h3>
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <p className="validuptoo">Valid Till 27.JUN.2024</p>
                          </div>

                          <div className="bookiingtab_div">
                            <Row>
                              <Col
                                xxl={3}
                                xl={3}
                                lg={4}
                                md={4}
                                sm={4}
                                xs={4}
                                className="pe-0"
                              >
                                <div className="imgsidemain forrdivv">
                                  <div className=" imggsssdiv">
                                    <img
                                      className="imgg"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/logo/logo.png"
                                      }
                                      alt="Logo"
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col
                                xxl={9}
                                xl={9}
                                lg={8}
                                md={8}
                                sm={8}
                                xs={8}
                                className="ps-0"
                              >
                                <div className="booktext_div subscrtabdiv subscrtabdiv2">
                                  <p className="stdtext mb-1">
                                    Starter Plan (₹99)
                                  </p>
                                  <p className="adrtext  mb-1">
                                    {" "}
                                    <FontAwesomeIcon
                                      icon={faSquareCheck}
                                      className="squrecheckiccn me-1"
                                    />{" "}
                                    Lorem Ipsum Dolor Sit Amet
                                  </p>
                                  <p className="adrtext  mb-1">
                                    {" "}
                                    <FontAwesomeIcon
                                      icon={faSquareCheck}
                                      className="squrecheckiccn me-1"
                                    />{" "}
                                    Lorem Ipsum Dolor Sit Amet
                                  </p>
                                  <p className="adrtext  mb-1">
                                    {" "}
                                    <FontAwesomeIcon
                                      icon={faSquareCheck}
                                      className="squrecheckiccn me-1"
                                    />{" "}
                                    Lorem Ipsum Dolor Sit Amet
                                  </p>
                                  <p className="adrtext  mb-1">
                                    {" "}
                                    <FontAwesomeIcon
                                      icon={faSquareCheck}
                                      className="squrecheckiccn me-1"
                                    />{" "}
                                    Lorem Ipsum Dolor Sit Amet
                                  </p>

                                  <div className=" update_div renediv">
                                    <p>Renew</p>
                                  </div>
                                  <div className="monthly_div">
                                    <h3
                                      className="monthly_p "
                                      style={{ margin: "0px 0px 0px 0px" }}
                                    >
                                      {" "}
                                      YEARLY &nbsp; &nbsp;{" "}
                                    </h3>
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <p className="validuptoo">Valid Till 27.JUN.2024</p>
                          </div>
                        </div>
                      </Tab.Pane>

                      <Tab.Pane eventKey="fiveth">
                        <p className="no-saved-pay">
                          No saved payment methods found
                        </p>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </Container>

        <Book_cancel_modal
          getAllBookingData={getAllBookingData}
          show={modalShow}
          ID={cancelId}
          onHide={() => setModalShow(false)}
        />

        <Historycard_view_modal
          ID={ADDId}
          show={modalShow1}
          onHide={() => setModalShow1(false)}
        />

        <Success_modal
          maintext={"Changes Saved Successfully"}
          smalltext={""}
          show={modalShow2}
          onHide={() => setModalShow2(false)}
        />

        <Add_review_modal
          getAllBookingDataHistory={getAllBookingDataHistory}
          studioId={studioId}
          show={modalShow3}
          onHide={() => setModalShow3(false)}
        />
      </section>
    </>
  );
}

export default Account;
