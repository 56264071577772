import React, { useEffect, useRef, useState, useContext } from 'react'
import "./Book_cancel_modal.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Success_modal from "../Success_modal/Success_modal";
import { useForm } from 'react-hook-form';
import { ProfileGet, EditProfile, getBooking, cancelBooking,postCancelBooking } from "../../../../utils/apis/myAccount/myAccount";
const Book_cancel_modal = (props) => {
  const [modalShow4, setModalShow4] = useState(false);
  const [data, setData] = useState();
  // const handleSubmit = () => {
  //   setModalShow4(true);
  //   props.onHide();
  // };
  const getAllData = async () => {
    const res = await cancelBooking();
    if (res?.success) {
      setData(res?.data);
    } else {
      console.error('Error fetching  data:', res?.error);
    }
  }
  useEffect(() => {
    getAllData();

  }, []);
  const { register, handleSubmit, watch } = useForm();
  const onSubmit = async (data) => {
    
    const datafinal = {
      reason_id: data?.reason_id,
      remark: data?.remark,
      book_studio_id: props?.ID,
     
    }
    const res = await postCancelBooking(datafinal);
    if (res?.success) {
      setModalShow4(true);
      props.onHide();
      props.getAllBookingData();
    }
  };
  

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="booking-cancel-modal"
      >
        {/* <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Modal heading
                    </Modal.Title>
                </Modal.Header> */}
        <Modal.Body>
          <div className="text-center">
            <h4 className="title">Booking Cancelation</h4>
          </div>

          <div className="reasons-div">
            <p className="resaop-pp">Select a reason for cancelation</p>

            <Form>
              {["radio"].map((type) => (
                <div key={`inline-${type}`} className="mb-3">
                  {data?.map((val) => (
                    <Form.Check
                      key={val?.id}
                      label={val?.name}
                      name="group1"
                      type={type}
                      id={val?.id}
                      value={val?.id} // Set the value to the ID
                      {...register("reason_id")} // Register the input with react-hook-form
                    />
                  ))}
                  {/* {data?.map((val)=>
                  <Form.Check
                    label={val?.name}
                    name="group1"
                    type={type}
                    id={val?.id}
                  />
                )} */}

                  {/* <Form.Check
                    label="Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry."
                    name="group1"
                    type={type}
                    id={`inline-${type}-2`}
                  />

                  <Form.Check
                    label="Lorem Ipsum Is Simply Dummy Text Of The Printing"
                    name="group1"
                    type={type}
                    id={`inline-${type}-3`}
                  />

                  <Form.Check
                    label="Lorem Ipsum Is Simply Dummy Text Of The Printing"
                    name="group1"
                    type={type}
                    id={`inline-${type}-4`}
                  />

                  <Form.Check
                    label="Other"
                    name="group1"
                    type={type}
                    id={`inline-${type}-5`}
                  /> */}
                </div>
              ))}
           

            <FloatingLabel
              controlId="floatingTextarea"
              label="Enter your reason..."
              className="mb-3"
            >
               <Form.Control
                    as="textarea"
                    type="text"
                    name="remark"
                    placeholder="Leave a comment here"
                    {...register("remark", {
                    
                    })}

                  />
              {/* <Form.Control as="textarea" placeholder="Leave a comment here" /> */}
            </FloatingLabel>
            </Form>
            <div className="d-flex justify-content-center mt-5">
              <button className="cancel-btn" onClick={props.onHide}>
                CANCEL
              </button>
              <button type="button" className="submit-btn"   onClick={handleSubmit(onSubmit)}>
                SUBMIT
              </button>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer> */}

      </Modal>


      <Success_modal
        maintext={"Booking Canceled Successfully"}
        show={modalShow4}
        onHide={() => setModalShow4(false)}
      />
    </>
  );
};

export default Book_cancel_modal;