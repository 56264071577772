import React, { useEffect } from "react";
import "./banner/Banner.css";
import Banner from "./banner/Banner";
import Home_about_us from "./about-us/Home_about_us";
import Studio_spaces from "./studio-spaces/Studio_spaces";
import Counter from "./counter/Counter";
import Why_fns from "./why-fns/Why_fns";
import Home_inquiry from "./inquiry/Home_inquiry";
import Motivated from "./we-are-motivated/Motivated";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Banner />
      <Home_about_us />
      <Studio_spaces />
      <Counter />
      <Why_fns />
      <Home_inquiry />
      <Motivated />
    </>
  );
}

export default Home;
