import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const Homebanner = async (id) => {
  try {
    return await getData(`/without-login/home/banner`);
  } catch (error) {
    console.log(error);
  }
};

export const SpaceStidio = async (id) => {
  try {
    return await getData(`/without-login/allcategories`);
  } catch (error) {
    console.log(error);
  }
};

export const AllFns = async (id) => {
  try {
    return await getData(`/without-login/home/all-fns`);
  } catch (error) {
    console.log(error);
  }
};

export const MakeUsSpecial = async (id) => {
  try {
    return await getData(`/without-login/home/all-specials`);
  } catch (error) {
    console.log(error);
  }
};

export const AllHappyCustomer = async (id) => {
  try {
    return await getData(`/without-login/all-happy-clients`);
  } catch (error) {
    console.log(error);
  }
};

export const AllSuccessfulProject = async (id) => {
  try {
    return await getData(`/without-login/all-successful-project`);
  } catch (error) {
    console.log(error);
  }
};

export const AllStudio = async (id) => {
  try {
    return await getData(`/without-login/all-studio`);
  } catch (error) {
    console.log(error);
  }
};

export const AllCategory = async (id) => {
  try {
    return await getData(`/without-login/all-category`);
  } catch (error) {
    console.log(error);
  }
};

export const MotivatedBy = async (id) => {
  try {
    return await getData(`/without-login/home/all-motivated-by`);
  } catch (error) {
    console.log(error);
  }
};

export const promotional_products = async (id) => {
  try {
    return await getData(`/home/promotional-products`);
  } catch (error) {
    console.log(error);
  }
};

export const blog = async (id) => {
  try {
    return await getData(`/home/promotional-products`);
  } catch (error) {
    console.log(error);
  }
};
