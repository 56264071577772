import React, { useRef, useState, useContext } from "react";
import "./studio-slider.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import Studio_big_slider_modal from "../../common-section/Modals/studio-big-slider-modal/Studio_big_slider_modal";

import { Context } from "../../../utils/context";

const Studio_slider = ({ data }) => {
  const [modalShow, setModalShow] = React.useState(false);

  const [swiperInstance, setSwiperInstance] = useState(null);
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  const { IMG_URL } = useContext(Context);
  return (
    <>
      <section className="Studio_slider">
        <div className="container">
          <div className="swiper-main">
            <Swiper
              navigation={false}
              rewind={true}
              modules={[Navigation]}
              className="mySwiper"
              onSwiper={(swiper) => setSwiperInstance(swiper)}
              // slidesPerView={1}
              spaceBetween={10}
              breakpoints={{
                0: {
                  slidesPerView: 2.5,
                  spaceBetween: 10,
                },

                575: {
                  slidesPerView: 3.5,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 4.5,
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 5.5,
                  spaceBetween: 10,
                },
              }}
            >
              {data?.studio_images?.map((val) => (
                <SwiperSlide>
                  <div
                    className="swiper-div"
                    onClick={() => setModalShow(true)}
                  >
                    <img className="stud-img" src={IMG_URL + val?.images} />
                  </div>
                </SwiperSlide>
              ))}
              {/* <SwiperSlide>
                                <div className='swiper-div' onClick={() => setModalShow(true)}>
                                    <img className='stud-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/slider-img.png'} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='swiper-div' onClick={() => setModalShow(true)}>
                                    <img className='stud-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/slider-img.png'} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='swiper-div' onClick={() => setModalShow(true)}>
                                    <img className='stud-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/slider-img.png'} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='swiper-div' onClick={() => setModalShow(true)}>
                                    <img className='stud-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/slider-img.png'} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='swiper-div' onClick={() => setModalShow(true)}>
                                    <img className='stud-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/slider-img.png'} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='swiper-div' onClick={() => setModalShow(true)}>
                                    <img className='stud-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/slider-img.png'} />
                                </div>
                            </SwiperSlide> */}

              <div class="silder-btn">
                <div
                  className="back-btn"
                  onClick={() => handleMainSliderClick("prev")}
                ></div>
                <div
                  className="next-btn"
                  onClick={() => handleMainSliderClick("next")}
                ></div>
              </div>
            </Swiper>
          </div>

          <Studio_big_slider_modal
            data={data}
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        </div>
      </section>
    </>
  );
};

export default Studio_slider;
