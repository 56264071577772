import React, { useEffect, useRef, useState, useContext } from "react";
import "./studio-spaces.css";
import { Context } from "../../../utils/context";
import { SpaceStidio } from "../../../utils/apis/Home/Homeapi";
const Studio_spaces = () => {
  const [data, setData] = useState();
  const { IMG_URL } = useContext(Context);
  const getAllData = async () => {
    const res = await SpaceStidio();
    if (res?.success) {
      setData(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);
  return (
    <>
      <section className="Studio_spaces_sec">
        <div className="container">
          <div className="title-div text-center">
            <h1 className="title mb-4">Studio Spaces</h1>
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  {data?.map((val) => (
                    <div className="col-4 mb-3" key={val?.id}>
                      <div className="ban-div">
                        <a href={`/media-spaces/${val?.id}`}>
                          <img
                            className="std-img"
                            src={IMG_URL + val?.image}
                            alt={val?.name}
                          />
                          <div className="overlay-text">
                            <p className="first-text">{val?.name}</p>
                          </div>
                        </a>
                      </div>
                    </div>
                  ))}

                  {/* {data?.map((val) =>
                                        <div className='col-4 mb-3 main-big-img-sec'>
                                            <div className='ban-div'>
                                                <img className='std-img' src={IMG_URL + val?.image} />
                                                <div className='overlay-text'>
                                                    <p className='first-text'>{val?.name}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )} */}
                  {/* <div className='col-6 mb-3'>
                                        <div className='ban-div'>
                                            <img className='std-img' src={process.env.PUBLIC_URL + '/assets/images/homepage/studio-space/img2.png'} />
                                            <div className='overlay-text'>
                                                <p className='first-text'>The Speak</p>
                                                <p className='first-text'>Easy Lounge</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-6 mb-3'>
                                        <div className='ban-div'>
                                            <img className='std-img' src={process.env.PUBLIC_URL + '/assets/images/homepage/studio-space/img2.png'} />
                                            <div className='overlay-text'>
                                                <p className='first-text'>The Speak</p>
                                                <p className='first-text'>Easy Lounge</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='col-6 mb-3'>
                                        <div className='ban-div'>
                                            <img className='std-img' src={process.env.PUBLIC_URL + '/assets/images/homepage/studio-space/img3.png'} />
                                            <div className='overlay-text'>
                                                <p className='first-text'>Podcast</p>
                                                <p className='first-text'>Lounge</p>
                                            </div>
                                        </div>
                                    </div> */}
                </div>
              </div>
              {/* <div className='col-md-4 col-12 mx-auto'>
                                {Array.isArray(data) && data.length > 0 && (
                                    <div className='ban-div'>
                                        <img className='std-img' src={IMG_URL + data[0]?.image} />
                                        <div className='overlay-text'>
                                            <p className='first-text'>{data[0]?.name}</p>

                                        </div>
                                    </div>
                                )}
                            </div> */}
              {/* <div className='col-md-4 col-12 mx-auto'>
                                <div className='ban-div'>
                                    <img className='std-img' src={process.env.PUBLIC_URL + '/assets/images/homepage/studio-space/img5.png'} />
                                    <div className='overlay-text'>
                                        <p className='first-text'>The</p>
                                        <p className='first-text'>Meetup</p>
                                    </div>
                                </div>
                            </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Studio_spaces;
