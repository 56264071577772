import React, { useEffect, useRef, useState, useContext } from "react";
import "./Our-mission.css";
import Counter from "../../home/counter/Counter";
import { Context } from "../../../utils/context";
import { OurMission, ReviewCount } from "../../../utils/apis/AboutUs/AboutUs";
import parse from "html-react-parser";
const Our_mission = () => {
  const [data, setData] = useState();
  const [review, setReview] = useState();
  const { IMG_URL } = useContext(Context);
  const getAllData = async () => {
    const res = await OurMission();
    if (res?.success) {
      setData(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const getReviewCount = async () => {
    const res = await ReviewCount();
    if (res?.success) {
      setReview(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getAllData();
    getReviewCount();
  }, []);
  return (
    <>
      <section className="Our_mission_sec">
        <div className="container">
          <div className="background">
            <div className="align-div text-center mb-5">
              <h1 className="mision-title">Our Mission & Vision</h1>
            </div>

            <div className="row mission-main ">

              {/* <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12 mb-5 order-md-1">
                    <p className="text">
                      Fostering innovation with every studio rental, shaping the
                      future of media creation.
                    </p>
                  </div>

                  <div className="col-md-12 mb-5 order-md-3 order-1">
                    <div className="feedback-div">
                      <div className="d-flex">
                        <img
                          className="per"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/about-us/our-mission/person.png"
                          }
                        />
                        <img
                          className="per"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/about-us/our-mission/person.png"
                          }
                        />
                        <img
                          className="per"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/about-us/our-mission/person.png"
                          }
                        />
                        <img
                          className="per"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/about-us/our-mission/person.png"
                          }
                        />
                        <img
                          className="per"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/about-us/our-mission/person.png"
                          }
                        />
                      </div>

                      <h6 className="count">
                        {review?.count}
                        <span>+</span>
                      </h6>
                      <p className="posi-fed">Positve Feedbacks</p>
                    </div>
                  </div>
                </div>
              </div> */}
              {data?.map((val) => (
                <div className="col-md-6 mb-4">
                  <div className="row">

                    <div className="col-md-12 mb-5 order-md-2">
                      <div className="d-flex">
                        <div className="icon-div">
                          <img className="icon" src={IMG_URL + val?.logo} />
                        </div>
                        <div className="content-div">
                          <h5 className="title">{val?.name}</h5>
                          <p className="text">{parse(val?.description)}</p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              ))}

              
              {/* <div className="col-md-6">
                <div className="row">
                  {data?.map((val) => (
                    <div className="col-md-12 mb-5 order-md-2">
                      <div className="d-flex">
                        <div className="icon-div">
                          <img className="icon" src={IMG_URL + val?.logo} />
                        </div>
                        <div className="content-div">
                          <h5 className="title">{val?.name}</h5>
                          <p className="text">{parse(val?.description)}</p>
                        </div>
                      </div>
                    </div>
                  ))}                  
                </div>
              </div>   */}


            </div>

            <div className="pronounced-sec text-center">
              <h1 className="title pb-4">Pronouned to Empower </h1>
              <Counter />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Our_mission;
