import React, { useEffect, useRef, useState, useContext } from "react";
import MediaBanner from "./MediaBanner/MediaBanner";
import MediaCardSec from "./MediaCardSec/MediaCardSec";
import Home_inquiry from "../home/inquiry/Home_inquiry";
import { Context } from "../../utils/context";
import { mediaSpace } from "../../utils/apis/MediaSpace/MediaSpace";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
const MediaSpaces = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  const [data, setData] = useState();

  console.log(data, "dddddddddddddddddddddddddddddddddddddddddddddddd");

  const [location, setLocation] = useState("");
  const [price, setPrice] = useState("");
  const [rating, setRating] = useState("");
  console.log(rating, "Rating");

  console.log(price.priceRange?.max, "pricepricepriceprice");

  const [onPageChange, setonPageChange] = useState(1);
  const { id } = useParams();
  const { IMG_URL } = useContext(Context);
  const [totalPages, settotalPages] = useState();
  const getAllData = async () => {
    const res = await mediaSpace(onPageChange, id, "", location, price, rating);
    if (res?.success) {
      setData(res?.data);
      settotalPages(res?.data?.totalPages);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };
  useEffect(() => {
    getAllData();
  }, [id, onPageChange, location, price, rating]);
  console.log(onPageChange, "onPageChange totalPages");

  return (
    <>
      {/* <MediaBanner data={data[0].category} /> */}
      {/* {data.length > 0 && data?.[0].category ? (
        <MediaBanner data={data?.data?.[0].category} />
      ) : (
        <p>No data available</p>
      )} */}

      <MediaBanner data={data?.data?.[0]?.category} />

      <MediaCardSec
        data={data}
        id={id}
        location={location}
        setLocation={setLocation}
        price={price}
        setPrice={setPrice}
        rating={rating}
        setRating={setRating}
        setonPageChange={setonPageChange}
        onPageChange={onPageChange}
        settotalPages={settotalPages}
        totalPages={totalPages}
      />
      <Home_inquiry id={id} />
    </>
  );
};

export default MediaSpaces;
