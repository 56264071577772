import { useEffect, useState } from "react";
import './loginform.css'
import Form from 'react-bootstrap/Form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faHouse } from '@fortawesome/free-solid-svg-icons'
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { Link } from 'react-router-dom';

import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import Success_modal from "../common-section/Modals/Success_modal/Success_modal";

const Setnew_password = () => {

    const [phoneValue, setPhoneValue] = useState("");
    const [modalShow, setModalShow] = useState(false);

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible);
    };


    const [passwordVisible2, setPasswordVisible2] = useState(false);
    const [confirmPasswordVisible2, setConfirmPasswordVisible2] = useState(false);
    const togglePasswordVisibility2 = () => {
        setPasswordVisible2(!passwordVisible2);
    };
    const toggleConfirmPasswordVisibility2 = () => {
        setConfirmPasswordVisible2(!confirmPasswordVisible2);
    };
    return (
        <>
            <section className='login-page-main-sec'>
                <div className='container'>
                    <div className='main-position-div'>
                        <div className='d-flex aligndiv'>


                            <div className='mid-img-div'>
                                <img src={process.env.PUBLIC_URL + '/assets/images/login/mid-img2.png'} />
                            </div>

                            <div className='last-div slidein margin-top'>
                                <div className='loginformsec topspace'>
                                    <Link to="/forgot-password"><FontAwesomeIcon className='home-icn' icon={faChevronLeft} /></Link>
                                    <h1 className='login-title'>Set New Password</h1>
                                    <p className='loginby'>Your new password must be different to previously
                                        used password.</p>

                                    <div className='form-div '>
                                        <Form >
                                            <Form.Group className='form-group mt-5'>
                                                <Form.Label>Enter Password</Form.Label>
                                                <Form.Control type="text" placeholder="Enter Your Password" />
                                                {/* <FontAwesomeIcon className='eye-icc' icon={faEye} /> */}
                                                <div
                                                    className="eye-icc-holder"
                                                    onClick={togglePasswordVisibility}
                                                >
                                                    <FontAwesomeIcon
                                                        className="eye-icc"
                                                        icon={
                                                            passwordVisible ? faEye : faEyeSlash
                                                        }
                                                    />
                                                </div>
                                            </Form.Group>

                                            <Form.Group className='form-group mb-5'>
                                                <Form.Label>Enter Password</Form.Label>
                                                <Form.Control type="text" placeholder="Enter Your Password" />
                                                {/* <FontAwesomeIcon className='eye-icc' icon={faEye} /> */}
                                                <div
                                                    className="eye-icc-holder"
                                                    onClick={togglePasswordVisibility2}
                                                >
                                                    <FontAwesomeIcon
                                                        className="eye-icc"
                                                        icon={
                                                            passwordVisible2 ? faEye : faEyeSlash
                                                        }
                                                    />
                                                </div>
                                            </Form.Group>

                                            <Link to="#"  ><button type="button" onClick={() => setModalShow(true)}   className='Login-btn resetpasstop'>Submit</button></Link>
                                        </Form>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <Success_modal
                maintext={"Password Reset Successfully"}
                smalltext={""}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    )
}

export default Setnew_password