import React from 'react'
import './MonthlyCard.css'
import Form from 'react-bootstrap/Form';
import { Col, Row } from 'react-bootstrap';
const MonthlyCard = ({ image, content, RupeesText }) => {
    return (
        <>
            <div className='montly-card'>
                <div className='form-start'>
                    <Form>
                        <Form.Group className="mb-3" id="formGridCheckbox1">
                            <Form.Check type="checkbox" label="Lorem Ipsum Dolor Sit Amet1" />
                        </Form.Group>
                        <Form.Group className="mb-3" id="formGridCheckbox2">
                            <Form.Check type="checkbox" label="Lorem Ipsum Dolor Sit Amet2" />
                        </Form.Group>
                        <Form.Group className="mb-3" id="formGridCheckbox3">
                            <Form.Check type="checkbox" label="Lorem Ipsum Dolor Sit Amet3" />
                        </Form.Group>
                        <Form.Group className="mb-3" id="formGridCheckbox4">
                            <Form.Check type="checkbox" label="Lorem Ipsum Dolor Sit Amet4" />
                        </Form.Group>
                    </Form>
                </div>
                <div className='image-plan' >
                    <img className="plan-img" src={image} alt="banlogo" />
                    <div className='overlay-color'></div>
                    <div className='text-plan '>
                        <h2>{content}</h2>
                    </div>
                </div>
                <div className='prize-text text-center py-3'>
                    <h2>{RupeesText}</h2>
                    <h3>Per Month</h3>
                    <button className='select-plan'>Select Plan</button>
                </div>
            </div>
        </>
    )
}

export default MonthlyCard
