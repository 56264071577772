import React from 'react'

const Subscription_banner = () => {
    return (
        <>
            <section className='About_banner_sec'>
                <div className='container-fluid p-0'>
                    <img className='banner-img' src={process.env.PUBLIC_URL + '/assets/images/subscription/Subscriptionbanner.png'} />
                    <div className='overlayblack'></div>
                    <div className='overlay-text'>
                        <h3 className='banner-title'>Our Plans for Your Plans</h3>
                        <h6 className='subtitle'>Get Your perfect fit</h6>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Subscription_banner