import React, { useEffect, useRef, useState, useContext } from "react";
import { Context } from "../../../utils/context";

const MediaBanner = ({ data }) => {
  const { IMG_URL } = useContext(Context);

  console.log(data, "dataaaaaa");

  return (
    <>
      <section className="About_banner_sec">
        <div className="container-fluid p-0">
          <img className="banner-img" src={IMG_URL + data?.large_image} />
          {/* <img
            className="banner-img"
            src={process.env.PUBLIC_URL + "/assets/images/studio/Banner1.png"}
          /> */}
          {/* <div className='overlayblack'></div> */}
          <div className="overlay-text">
            <h3 className="banner-title">{data?.name}</h3>
            <h6 className="subtitle"></h6>
          </div>
        </div>
      </section>
    </>
  );
};

export default MediaBanner;
