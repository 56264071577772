import {
  postData
} from "../../api";

export const userRegister = async (data) => {
  try {
    const res = await postData(`/auth/register`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const userLogin = async (data) => {
  try {
    const res = await postData(`/auth/login`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const sellerLogin = async (data) => {
  try {
    const res = await postData(`/admin/login`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};
