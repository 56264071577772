import { useState, useEffect, useContext } from "react";
import "../../bookings-form.css";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";

import "react-phone-input-2/lib/style.css";

import Accordion from "react-bootstrap/Accordion";
import Success_modal from "../../../common-section/Modals/Success_modal/Success_modal";
import { useForm, Controller } from "react-hook-form";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { RegxExpression } from "../../../../utils/common";

import classNames from "classnames";
import useRazorpay from "react-razorpay";
import { getAdditionalSupport } from "../../../../utils/apis/master/Master";
import { postBooking } from "../../../../utils/apis/booking/booking";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../../utils/context";
const Daily_form = ({ data }) => {
  const { IMG_URL, appSetup } = useContext(Context);

  console.log(appSetup?.platform_charges, "platfrom chartges");

  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [value, onChange] = useState("10:00");

  const [phoneValue, setPhoneValue] = useState("");
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
    watch,
    setValue,
  } = useForm();

  const watchStartDate = watch("start_date");
  const watchEndDate = watch("end_date");
  const watchStartTime = watch("start_time");
  const watchEndTime = watch("end_time");

  const [studioId, seStudioId] = useState();
  // // Watch start_time and end_time fields

  const [useDays, setUseDays] = useState();
  console.log(useDays, "useDays");

  const [useRoundedHours, setRoundedHours] = useState();
  const [checked, setChecked] = useState(false);

  console.log(useRoundedHours, "useRoundedHours");

  useEffect(() => {
    if (watchStartDate) {
      setStartDate(new Date(watchStartDate));
    }
  }, [watchStartDate]);
  const [totalCharge, setTotalCharge] = useState(0);
  const [chargesError, setChargesError] = useState(false);

  useEffect(() => {
    seStudioId(data?.id);
    if (watchStartDate && watchEndDate && watchStartTime && watchEndTime) {
      const startDate = new Date(watchStartDate);
      const endDate = new Date(watchEndDate);
      const startTime = new Date(`1970-01-01T${watchStartTime}:00`);
      const formattedStartTime = startTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      });
      const endTime = new Date(`1970-01-01T${watchEndTime}:00`);
      const formattedEndTime = endTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      });

      const days = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24) + 1);
      setUseDays(days);

      setValue("days", days);
      function parseTimeToMilliseconds(timeStr) {
        const [hours, minutes] = timeStr.split(":").map(Number);
        return (hours * 60 + minutes) * 60 * 1000; // Convert to milliseconds
      }

      const formattedStartTimenow = formattedStartTime;
      const formattedEndTimenow = formattedEndTime;

      const startTimeMs = parseTimeToMilliseconds(formattedStartTimenow);
      const endTimeMs = parseTimeToMilliseconds(formattedEndTimenow);

      const hours = Math.max((endTimeMs - startTimeMs) / (1000 * 60 * 60), 0);
      const roundedHours = Math.ceil(hours);
      setRoundedHours(roundedHours);

      console.log(data, "data");
      console.log(roundedHours, "roundedHours");
      console.log(formattedStartTime, "formattedStartTime");
      console.log(formattedEndTime, "formattedEndTime");
      console.log(data?.full_d_start_time, "full day start time");
      console.log(data?.full_d_end_time, "full day end time");
      console.log(data?.full_day_hours, "full day Hours");
      console.log(data?.minimum_hours, "Minimum Hours");
      if (days === 1) {
        if (formattedStartTime < data?.full_d_start_time) {
          console.log("Studio Not Started Yet");
          setChargesError("Studio Not Started Yet");
          return;
        }
        if (formattedStartTime < data?.full_d_start_time) {
          console.log("Studio Not Started Yet");
          setChargesError("Studio Not Started Yet");
          return;
        }

        if (formattedEndTime > data?.full_d_end_time) {
          console.log("Studio Closed after This Time");
          setChargesError("Studio Closed after This Time");
          return;
        }

        // if (roundedHours < data?.minimum_hours) {
        //   console.log("Not Eligible");
        //   setChargesError("Not Eligible: Please Increase Your Time");
        //   return;
        // }

        if (roundedHours > data?.full_day_hours) {
          console.log("Working Hour Is Over");
          setChargesError("Working Hour Is Over");
          return;
        }

        // Handle exact match for half-day hours
        if (roundedHours === data?.half_day_hours) {
          console.log("You are at half day hours");
          // setChargesError(`Price Money is ${data?.half_day_charges}`);
          setTotalCharge(data?.half_day_charges);
          return;
        }

        // Handle exact match for full-day hours
        if (roundedHours === data?.full_day_hours) {
          console.log("You are at full day hours");
          // setChargesError(`Price Money is ${data?.full_day_charges}`);
          setTotalCharge(data?.full_day_charges);
          return;
        }

        // Handle the range between half-day and full-day hours
        if (
          roundedHours > data?.half_day_hours &&
          roundedHours < data?.full_day_hours
        ) {
          const price =
            data?.half_day_charges +
            data?.hourly_basis_charges * (roundedHours - data?.half_day_hours);
          console.log("You are between half day and full day");
          // setChargesError(`Your Price Money is ${price}`);
          setTotalCharge(price);
          return;
        }

        // Handle range between minimum and half-day hours
        if (
          roundedHours > data?.minimum_hours &&
          roundedHours < data?.half_day_hours
        ) {
          const price = data?.hourly_basis_charges * roundedHours;
          console.log("You are between minimum and half day hours");
          // setChargesError(`Price Money is ${price}`);
          setTotalCharge(price);
        }

        if (roundedHours == data?.minimum_hours) {
          const price = data?.hourly_basis_charges * roundedHours;
          console.log("You are between minimum and half day hours");
          // setChargesError(`Price Money is ${price}`);
          setTotalCharge(price);
        }

        setChargesError("");
      }

      if (days > 1) {
        if (formattedStartTime < data?.full_d_start_time) {
          console.log("Studio Not Started Yet");
          setChargesError("Studio Not Started Yet");
          return;
        }

        if (formattedEndTime > data?.full_d_end_time) {
          console.log("Studio Closed after This Time");
          setChargesError("Studio Closed after This Time");
          return;
        }

        if (roundedHours < data?.minimum_hours) {
          console.log("Not Eligible");
          setChargesError("Not Eligible: Please Increase Your Time");
          return;
        }

        if (roundedHours > data?.full_day_hours) {
          console.log("Working Hour Is Over");
          setChargesError("Working Hour Is Over");
          return;
        }

        // Handle exact match for half-day hours
        if (roundedHours === data?.half_day_hours) {
          console.log("You are at half day hours");
          // setChargesError(`Price Money is ${data?.half_day_charges}`);
          setTotalCharge(data?.half_day_charges * days);
          return;
        }

        // Handle exact match for full-day hours
        if (roundedHours === data?.full_day_hours) {
          console.log("You are at full day hours");
          // setChargesError(`Price Money is ${data?.full_day_charges}`);
          setTotalCharge(data?.full_day_charges * days);
          return;
        }

        // Handle the range between half-day and full-day hours
        if (
          roundedHours > data?.half_day_hours &&
          roundedHours < data?.full_day_hours
        ) {
          const price =
            data?.half_day_charges +
            data?.hourly_basis_charges * (roundedHours - data?.half_day_hours);
          console.log("You are between half day and full day");
          // setChargesError(`Your Price Money is ${price}`);
          setTotalCharge(price * days);
          return;
        }

        // Handle range between minimum and half-day hours
        if (
          roundedHours > data?.minimum_hours &&
          roundedHours < data?.half_day_hours
        ) {
          const price = data?.hourly_basis_charges * roundedHours;
          console.log("You are between minimum and half day hours");
          // setChargesError(`Price Money is ${price}`);
          setTotalCharge(price * days);
        }

        if (roundedHours == data?.minimum_hours) {
          const price = data?.hourly_basis_charges * roundedHours;
          console.log("You are between minimum and half day hours");
          // setChargesError(`Price Money is ${price}`);
          setTotalCharge(price * days);
        }
        setChargesError("");
      }

      // Calculate charges
      // const dayCharges = days * data?.full_day_charges;
      // const hourCharges = TimeDifference * data?.hourly_basis_charges;

      // setTotalCharge(dayCharges + hourCharges);
    }
  }, [watchStartDate, watchEndDate, watchStartTime, watchEndTime]);

  const selectedAddOns = watch("add_on_id");

  console.log(selectedAddOns, "selectedAddOns");

  const [checkedAddOns, setCheckedAddOns] = useState([]);
  const [totalAddOnCharges, setTotalAddOnCharges] = useState(0);

  const handleCheckboxChange = (id, checked, rate) => {
    const currentAddOns = selectedAddOns || [];
    console.log(rate, "checked");

    if (checked) {
      const newTotalCharge = totalCharge + Number(rate);
      setValue("add_on_id", [...currentAddOns, id]);
      setTotalCharge(newTotalCharge);
      setTotalAddOnCharges((prevTotal) => prevTotal + Number(rate));
    } else {
      const newTotalCharge = totalCharge - Number(rate);
      setValue(
        "add_on_id",
        currentAddOns.filter((itemId) => itemId !== id)
      );
      setTotalCharge(newTotalCharge);
      setTotalAddOnCharges((prevTotal) => prevTotal - Number(rate));
    }
  };

  const selectedsupport = watch("additional_support_id");

  const handleCheckboxChangeSupport = (id, checked) => {
    const currentAddOns = selectedsupport || [];
    if (checked) {
      setValue("additional_support_id", [...currentAddOns, id]);
    } else {
      setValue(
        "additional_support_id",
        currentAddOns.filter((itemId) => itemId !== id)
      );
    }
  };

  const [dataSupport, setDataSupport] = useState();
  const getAllData = async () => {
    const res = await getAdditionalSupport();
    if (res?.success) {
      setDataSupport(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  const [Razorpay, openRazorpay] = useRazorpay();
  const [razorid, setRazorId] = useState();
  const onSubmit = (datafinal) => {
    const selectedStartDate = new Date(datafinal.start_date)
      .toISOString()
      .split("T")[0];

    const selectedEndDate = new Date(datafinal.end_date)
      .toISOString()
      .split("T")[0];
    const { start_time, end_time } = datafinal;
    // datafinal?.days = datafinal;

    // Check for booking conflicts

    const isBooked = data?.book_studios?.some((booking) => {
      const bookingStartDate = new Date(booking.start_date)
        .toISOString()
        .split("T")[0];

      const bookingEndDate = new Date(booking.end_date)
        .toISOString()
        .split("T")[0];
      const bookingDate = new Date(booking.date).toISOString().split("T")[0];

      // Check if selected date range overlaps with booking date range
      const isDateOverlap =
        (selectedStartDate >= bookingStartDate &&
          selectedStartDate <= bookingEndDate) ||
        (selectedEndDate >= bookingStartDate &&
          selectedEndDate <= bookingEndDate) ||
        (bookingStartDate >= selectedStartDate &&
          bookingStartDate <= selectedEndDate) ||
        (bookingEndDate >= selectedStartDate &&
          bookingEndDate <= selectedEndDate) ||
        selectedStartDate === bookingDate ||
        selectedEndDate === bookingDate;

      const isTimeOverlap =
        (start_time >= booking.start_time && start_time < booking.end_time) ||
        (end_time > booking.start_time && end_time <= booking.end_time) ||
        (start_time <= booking.start_time && end_time >= booking.end_time);

      return isDateOverlap && isTimeOverlap;
    });

    // setError(chargesError);

    if (isBooked || chargesError) {
      if (chargesError !== 0) {
        console.log(chargesError);
      }
      if (isBooked) {
        setError("end_time", {
          type: "manual",
          message:
            "This time slot is already booked within the selected date range.",
        });
      }
    } else {
      clearErrors("end_time");
      handleRazorpay(datafinal);
      console.log("Form submitted successfully");
    }
  };

  const handleRazorpay = async (data) => {
    const options: RazorpayOptions = {
      key: "rzp_test_I05KCFb1v5JJRc",
      amount: Math.round(totalCharge + appSetup?.platform_charges) * 100,

      currency: "INR",
      name: "FlickNShoot",
      description: "Test Transaction",
      image:
        "http://localhost:3000/static/media/hippicart-logo.427718645d3015dbf003.png",

      handler: async (res) => {
        await setRazorId(res.razorpay_payment_id);
        finalFunction(data, res.razorpay_payment_id);
        console.log(res, "ttttttttttttttttttttttttttttt");
      },
      prefill: {
        name: "Piyush Garg",
        email: "youremail@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#052c65",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  };

  const [loading, setLoading] = useState(false);

  // const [error, setError] = useState(0);
  const finalFunction = async (data, payment_id) => {
    setLoading(true); // Start the loader

    const datafinal = {
      studio_id: studioId,
      type: "Daily",
      start_time: data?.start_time,
      end_time: data?.end_time,
      start_date: data?.start_date,
      end_date: data?.end_date,
      name: data?.name,
      amount: totalCharge + appSetup?.platform_charges,
      payment_id: payment_id,
      contact_no: data?.contact_no,
      days: data?.days,
      add_on_id: data?.add_on_id,
      additional_support_id: data?.additional_support_id,
      support_description: data?.support_description,
    };

    if (data?.email) {
      datafinal.email = data?.email;
    }

    if (data?.description) {
      datafinal.description = data?.description;
    }

    const res = await postBooking(datafinal);

    if (res?.success) {
      reset();
      setModalShow(true);
      setTimeout(() => {
        setModalShow(false);
        navigate("/"); // Navigate to the home page
      }, 3000);
    } else {
      setError("message", res?.message);
    }

    setLoading(false); // Stop the loader
  };

  const errorStyle = {
    color: "red",
    fontSize: "14px",
    margin: "10px 0",
  };

  const calculateEndTime = (startTime, hours) => {
    if (!startTime || !hours) return;

    // Parse start time
    const [startHour, startMinute] = startTime.split(":").map(Number);
    const startDate = new Date();
    startDate.setHours(startHour, startMinute);

    // Add hours to start time
    startDate.setHours(startDate.getHours() + Number(hours));

    // Format the end time in HH:mm format
    const endHour = String(startDate.getHours()).padStart(2, "0");
    const endMinute = String(startDate.getMinutes()).padStart(2, "0");

    return `${endHour}:${endMinute}`;
  };

  const handleHoursChange = (value) => {
    const startTime = getValues("start_time");
    if (startTime) {
      const endTime = calculateEndTime(startTime, value);
      setValue("end_time", endTime); // Automatically set the end time
    }
    setValue("hours", value);
  };

  return (
    <>
      {loading && <div className="loader">Loading...</div>}
      <div className="booking-from-sec">
        <div className="row">
          <div className="col-lg-11 mx-auto">
            <div className="form-bg">
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                    <Controller
                      name="start_date"
                      control={control}
                      defaultValue={new Date()}
                      render={({ field }) => (
                        <DatePicker
                          selected={field.value}
                          onChange={(date) => field.onChange(date)}
                          autoComplete="off"
                          className="form-control"
                          placeholderText="MM/DD/YYYY"
                          minDate={new Date()}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                    <Controller
                      name="end_date"
                      control={control}
                      defaultValue={new Date()}
                      render={({ field }) => (
                        <DatePicker
                          selected={field.value}
                          onChange={(date) => field.onChange(date)}
                          autoComplete="off"
                          className="form-control"
                          placeholderText="MM/DD/YYYY"
                          minDate={startDate}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                    <Controller
                      name="start_time"
                      control={control}
                      render={({ field }) => (
                        <TimePicker
                          className="form-control"
                          onChange={(time) => {
                            field.onChange(time);
                            setValue("start_time", time);
                            setChargesError("");
                          }}
                          value={field.value}
                          disableClock={true}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                    <Controller
                      name="hours"
                      control={control}
                      rules={{
                        required: "Hours are required",
                        min: { value: 1, message: "Give Positive Value" },
                      }}
                      render={({ field }) => (
                        <Form.Control
                          type="number"
                          name="hours"
                          placeholder="Enter Hours"
                          value={field.value}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value >= 0) {
                              // Ensure the value is non-negative
                              handleHoursChange(value); // Trigger end time calculation
                              field.onChange(value);
                            }
                          }}
                          className={classNames("", {
                            "is-invalid": errors?.hours,
                            "is-valid": getValues("hours"),
                          })}
                        />
                      )}
                    />
                    {errors?.hours && (
                      <span className="invalid-feedback">
                        {errors.hours.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                    <Controller
                      name="end_time"
                      control={control}
                      render={({ field }) => (
                        <TimePicker
                          disabled
                          className="form-control"
                          onChange={(time) => {
                            field.onChange(time);
                            setValue("end_time", time);
                            setChargesError("");
                          }}
                          value={field.value}
                          disableClock={true}
                        />
                      )}
                    />
                    {errors.end_time && (
                      <div className="text-danger">
                        {errors.end_time.message}
                      </div>
                    )}
                  </div>
                  {chargesError && <div style={errorStyle}>{chargesError}</div>}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-11 mx-auto mt-5">
            <div className="form-bg">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <Form.Label>Whole day Charges:</Form.Label>
                    <p className="charges-text-holder">
                      {data?.full_day_charges} ( {data?.full_day_hours} Hours)
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <Form.Label>Half day Charges:</Form.Label>
                    <p className="charges-text-holder">
                      {data?.half_day_charges}( {data?.half_day_hours} Hours)
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <Form.Label>Hourly Charges:</Form.Label>
                    <p className="charges-text-holder">
                      {data?.hourly_basis_charges}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-9 col-md-12 mx-auto ">
            <Form>
              <h6 className="estimatedh6">
                Estimated Booking Cost: ₹{" "}
                {totalCharge + appSetup?.platform_charges
                // Number(
                //   data?.studio_add_ons
                //     ?.map((value) => {
                //       return value?.rate;
                //     })
                //     .join(", ")
                // )
                }
                /-
              </h6>
              {errors.end_time && (
                <div className="text-danger">{errors.end_time.message}</div>
              )}
              <div className="form-bg">
                <div className="form-group">
                  <Form.Label>Enter Name*</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Enter Name"
                    {...register("name", {
                      required: "Name required",
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.name,
                      "is-valid": getValues("name"),
                    })}
                    onKeyDown={(event) => {
                      if (!RegxExpression.name.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </div>

                <div className="form-group">
                  <Form.Label>Enter Email*</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    placeholder="Enter Email"
                    {...register("email", {
                      // required: "Email is required",
                      pattern: {
                        value: RegxExpression.email,
                        message: "Invalid email address",
                      },
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.email,
                      "is-valid": getValues("email"),
                    })}
                  />
                  {errors?.email && (
                    <sup className="text-danger">{errors?.email?.message}</sup>
                  )}
                </div>

                <div className="form-group">
                  <Form.Label>Enter Contact Number*</Form.Label>
                  <Controller
                    name="contact_no"
                    control={control}
                    rules={{
                      required: "Field is required",
                      minLength: {
                        value: 10,
                        message: "Number should be at least 10 characters",
                      },
                    }}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        className={classNames("", {
                          "is-invalid": errors?.contact_no,
                          "is-valid": getValues("contact_no"),
                        })}
                        onChange={(value) => field.onChange(value)}
                        type="text"
                        placeholder="Enter Mobile Number"
                        onKeyDown={(event) => {
                          if (!RegxExpression.phone.test(event.key)) {
                            if (event.key !== "Backspace") {
                              event.preventDefault();
                            }
                          }
                        }}
                        maxLength={10}
                      />
                    )}
                  />
                  {errors?.contact_no && (
                    <sup className="text-danger">
                      {errors?.contact_no?.message}
                    </sup>
                  )}
                </div>

                <div className="form-group">
                  <Form.Label>Enter Project Description*</Form.Label>
                  <Form.Control
                    as="textarea"
                    type="text"
                    name="name"
                    placeholder="Enter Description"
                    {...register("description", {
                      // required: "Description required",
                    })}
                  />
                </div>
              </div>

              <div className="form-bg mt-3">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Add Ons<span> (Optional)</span>{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="acc-opn mb-3">
                        <div className="add-one">
                          <h6 className="addh6">ADD-ONS</h6>
                          <Controller
                            name="add_on_id"
                            control={control}
                            defaultValue={[]}
                            render={({ field }) => (
                              <>
                                {data?.studio_add_ons?.map((val) => (
                                  <Form.Check
                                    key={val.id} // Assuming `id` is the unique identifier
                                    type="checkbox"
                                    label={`${val.add_on.name} + ${val.rate}`}
                                    onChange={(e) => {
                                      setChecked(e.target.checked); // This will set true when checked and false when unchecked
                                      handleCheckboxChange(
                                        val.id,
                                        e.target.checked,
                                        val.rate
                                      );
                                    }}
                                    checked={field.value.includes(val.id)}
                                  />
                                ))}
                              </>
                            )}
                          />
                        </div>
                      </div>
                      <div className="acc-opn">
                        <div className="add-one">
                          <h6 className="addh6">ADDITIONAL SUPPORT</h6>
                          <Form>
                            <Controller
                              name="additional_support_id"
                              control={control}
                              defaultValue={[]} // Ensure default value is an empty array
                              render={({ field }) => (
                                <>
                                  {dataSupport?.map((val) => (
                                    <Form.Check
                                      key={val.id} // Assuming `id` is the unique identifier
                                      type="checkbox"
                                      label={`${val.name}`}
                                      onChange={(e) =>
                                        handleCheckboxChangeSupport(
                                          val.id,
                                          e.target.checked
                                        )
                                      }
                                      checked={field.value.includes(val.id)} // Ensure field.value is an array
                                    />
                                  ))}
                                </>
                              )}
                            />
                            <div className="form-group mt-5">
                              <Form.Label className="addlabhh">
                                Add Additional Support
                              </Form.Label>
                              {/* <Form.Control
                                as="textarea"
                                placeholder="Type here..."
                              /> */}
                              <Form.Control
                                as="textarea"
                                type="text"
                                name="name"
                                placeholder="Enter Description"
                                {...register("support_description", {})}
                              />
                            </div>
                            {useDays == 1 && (
                              <div className="Bifurcation_charges_text">
                                <div className="row justify-content-end">
                                  <div className="col-xl-5 col-lg-5  col-md-12 col-sm-12 col-12">
                                    <div className="heading-holder">
                                      <h3>Bifurcation Of Charges</h3>
                                    </div>

                                    {useRoundedHours ===
                                      data?.half_day_hours && (
                                      <div className="Bifurcation_content-holder">
                                        <div className="text-holder">
                                          <h4>Days:-</h4>
                                          <span>1</span>
                                        </div>
                                        <div className="text-holder">
                                          <h4>Full Day Charges:-</h4>
                                          <span>-</span>
                                        </div>
                                        <div className="text-holder">
                                          <h4>Half Day Charges:-</h4>
                                          <span>
                                            ₹ {data?.half_day_charges}
                                          </span>
                                        </div>
                                        <div className="text-holder">
                                          <h4>Hourly Basis Charges:-</h4>
                                          <span>-</span>
                                        </div>{" "}
                                        {
                                          <div className="text-holder">
                                            <h4>Add On Charges:</h4>
                                            <span>
                                              {checkedAddOns
                                                .map((id) => {
                                                  const addOn = data?.studio_add_ons.find(
                                                    (item) => item.id === id
                                                  );
                                                  return addOn?.rate;
                                                })
                                                .join(" + ")}{" "}
                                              {totalAddOnCharges}
                                            </span>
                                          </div>
                                        }
                                        <div className="text-holder">
                                          <h4> PlatFrom Charges :-</h4>
                                          <span>
                                            {appSetup?.platform_charges}
                                          </span>
                                        </div>
                                        <hr></hr>
                                        <div className="text-holder">
                                          <h4>Total:-</h4>
                                          <span>
                                            ₹{" "}
                                            {checked == true
                                              ? totalCharge +
                                                appSetup?.platform_charges
                                              : data?.half_day_charges +
                                                +appSetup?.platform_charges}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                    {useRoundedHours ===
                                      data?.full_day_hours && (
                                      <div className="Bifurcation_content-holder">
                                        <div className="text-holder">
                                          <h4>Days:-</h4>
                                          <span>1</span>
                                        </div>
                                        <div className="text-holder">
                                          <h4>Full Day Charges:-</h4>
                                          <span>
                                            ₹ {data?.full_day_charges}
                                          </span>
                                        </div>
                                        <div className="text-holder">
                                          <h4>Half Day Charges:-</h4>
                                          <span>-</span>
                                        </div>
                                        <div className="text-holder">
                                          <h4>Hourly Basis Charges:-</h4>
                                          <span>-</span>
                                        </div>{" "}
                                        {
                                          <div className="text-holder">
                                            <h4>Add On Charges:</h4>
                                            <span>
                                              {checkedAddOns
                                                .map((id) => {
                                                  const addOn = data?.studio_add_ons.find(
                                                    (item) => item.id === id
                                                  );
                                                  return addOn?.rate;
                                                })
                                                .join(" + ")}{" "}
                                              {totalAddOnCharges}
                                            </span>
                                          </div>
                                        }
                                        <div className="text-holder">
                                          <h4> PlatFrom Charges :-</h4>
                                          <span>
                                            {appSetup?.platform_charges}
                                          </span>
                                        </div>
                                        <hr></hr>
                                        <div className="text-holder">
                                          <h4>Total:-</h4>
                                          <span>
                                            ₹{" "}
                                            {checked == true
                                              ? totalCharge +
                                                appSetup?.platform_charges
                                              : data?.full_day_charges +
                                                +appSetup?.platform_charges}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                    {useRoundedHours > data?.half_day_hours &&
                                      useRoundedHours <
                                        data?.full_day_hours && (
                                        <div className="Bifurcation_content-holder">
                                          <div className="text-holder">
                                            <h4>Days:-</h4>
                                            <span>1</span>
                                          </div>
                                          <div className="text-holder">
                                            <h4>Full Day Charges:-</h4>
                                            <span>-</span>
                                          </div>
                                          <div className="text-holder">
                                            <h4>Half Day Charges:-</h4>
                                            <span>
                                              {data?.half_day_charges}
                                            </span>
                                          </div>
                                          <div className="text-holder">
                                            <h4>
                                              {" "}
                                              Hourly Basis Charges x{" "}
                                              {useRoundedHours -
                                                data?.half_day_hours}
                                              :-
                                            </h4>
                                            <span>
                                              {data?.hourly_basis_charges}
                                            </span>
                                          </div>{" "}
                                          {
                                            <div className="text-holder">
                                              <h4>Add On Charges:</h4>
                                              <span>
                                                {checkedAddOns
                                                  .map((id) => {
                                                    const addOn = data?.studio_add_ons.find(
                                                      (item) => item.id === id
                                                    );
                                                    return addOn?.rate;
                                                  })
                                                  .join(" + ")}{" "}
                                                {totalAddOnCharges}
                                              </span>
                                            </div>
                                          }
                                          <div className="text-holder">
                                            <h4> PlatFrom Charges :-</h4>
                                            <span>
                                              {appSetup?.platform_charges}
                                            </span>
                                          </div>
                                          <hr></hr>
                                          <div className="text-holder">
                                            <h4>Total:-</h4>
                                            <span>
                                              {checked == true
                                                ? totalCharge +
                                                  appSetup?.platform_charges
                                                : data?.half_day_charges +
                                                  data?.hourly_basis_charges *
                                                    (useRoundedHours -
                                                      data?.half_day_hours) +
                                                  +appSetup?.platform_charges}{" "}
                                              ₹
                                            </span>
                                          </div>
                                        </div>
                                      )}

                                    {useRoundedHours > data?.minimum_hours &&
                                      useRoundedHours <
                                        data?.half_day_hours && (
                                        <div className="Bifurcation_content-holder">
                                          <div className="text-holder">
                                            <h4>Days:-</h4>
                                            <span>1</span>
                                          </div>
                                          <div className="text-holder">
                                            <h4>Full Day Charges:-</h4>
                                            <span>-</span>
                                          </div>
                                          <div className="text-holder">
                                            <h4>Half Day Charges:-</h4>
                                            <span>-</span>
                                          </div>
                                          <div className="text-holder">
                                            <h4>
                                              Hourly Basis Charges x{" "}
                                              {useRoundedHours
                                                ? useRoundedHours
                                                : 0}
                                              :-
                                            </h4>
                                            <span>
                                              ₹{" "}
                                              {data?.hourly_basis_charges *
                                                useRoundedHours}{" "}
                                            </span>
                                          </div>

                                          {
                                            <div className="text-holder">
                                              <h4>Add On Charges:</h4>
                                              <span>
                                                {checkedAddOns
                                                  .map((id) => {
                                                    const addOn = data?.studio_add_ons.find(
                                                      (item) => item.id === id
                                                    );
                                                    return addOn?.rate;
                                                  })
                                                  .join(" + ")}{" "}
                                                {totalAddOnCharges}
                                              </span>
                                            </div>
                                          }
                                          <div className="text-holder">
                                            <h4> PlatFrom Charges :-</h4>
                                            <span>
                                              {appSetup?.platform_charges}
                                            </span>
                                          </div>
                                          <hr></hr>
                                          <div className="text-holder">
                                            <h4>Total:-</h4>
                                            <span>
                                              {checked == true
                                                ? totalCharge +
                                                  appSetup?.platform_charges
                                                : data?.hourly_basis_charges *
                                                    useRoundedHours +
                                                  +appSetup?.platform_charges}{" "}
                                              ₹
                                            </span>
                                          </div>
                                        </div>
                                      )}

                                    {useRoundedHours == data?.minimum_hours && (
                                      <div className="Bifurcation_content-holder">
                                        <div className="text-holder">
                                          <h4>Days:-</h4>
                                          <span>1</span>
                                        </div>
                                        <div className="text-holder">
                                          <h4>Full Day Charges:-</h4>
                                          <span>-</span>
                                        </div>
                                        <div className="text-holder">
                                          <h4>Half Day Charges:-</h4>
                                          <span>-</span>
                                        </div>
                                        <div className="text-holder">
                                          <h4>
                                            Hourly Basis Charges x{" "}
                                            {useRoundedHours
                                              ? useRoundedHours
                                              : 0}
                                            :-
                                          </h4>
                                          <span>
                                            ₹{" "}
                                            {data?.hourly_basis_charges *
                                              useRoundedHours}{" "}
                                          </span>
                                        </div>

                                        {
                                          <div className="text-holder">
                                            <h4>Add On Charges:</h4>
                                            <span>
                                              {checkedAddOns
                                                .map((id) => {
                                                  const addOn = data?.studio_add_ons.find(
                                                    (item) => item.id === id
                                                  );
                                                  return addOn?.rate;
                                                })
                                                .join(" + ")}{" "}
                                              {totalAddOnCharges}
                                            </span>
                                          </div>
                                        }
                                        <div className="text-holder">
                                          <h4> PlatFrom Charges :-</h4>
                                          <span>
                                            {appSetup?.platform_charges}
                                          </span>
                                        </div>
                                        <hr></hr>
                                        <div className="text-holder">
                                          <h4>Total:-</h4>
                                          <span>
                                            ₹{" "}
                                            {checked == true
                                              ? totalCharge +
                                                appSetup?.platform_charges
                                              : data?.hourly_basis_charges *
                                                  useRoundedHours +
                                                // Number(
                                                //   data?.studio_add_ons
                                                //     ?.map((value) => {
                                                //       return value?.rate;
                                                //     })
                                                //     .join(", ")
                                                // )
                                                +appSetup?.platform_charges}{" "}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}{" "}
                            {useDays > 1 && (
                              <div className="Bifurcation_charges_text">
                                <div className="row justify-content-end">
                                  <div className="col-xl-5 col-lg-5  col-md-12 col-sm-12 col-12">
                                    <div className="heading-holder">
                                      <h3>Bifurcation Of Charges</h3>
                                    </div>

                                    {useRoundedHours ===
                                      data?.half_day_hours && (
                                      <div className="Bifurcation_content-holder">
                                        <div className="text-holder">
                                          <h4>Days:-</h4>
                                          <span>{useDays}</span>
                                        </div>
                                        <div className="text-holder">
                                          <h4>Full Day Charges:-</h4>
                                          <span>-</span>
                                        </div>
                                        <div className="text-holder">
                                          <h4>Half Day Charges:-</h4>
                                          <span>
                                            ₹ {data?.half_day_charges}
                                          </span>
                                        </div>
                                        <div className="text-holder">
                                          <h4>Hourly Basis Charges:-</h4>
                                          <span>-</span>
                                        </div>{" "}
                                        {
                                          <div className="text-holder">
                                            <h4>Add On Charges:</h4>
                                            <span>
                                              {checkedAddOns
                                                .map((id) => {
                                                  const addOn = data?.studio_add_ons.find(
                                                    (item) => item.id === id
                                                  );
                                                  return addOn?.rate;
                                                })
                                                .join(" + ")}{" "}
                                              {totalAddOnCharges}
                                            </span>
                                          </div>
                                        }
                                        <div className="text-holder">
                                          <h4> PlatFrom Charges :-</h4>
                                          <span>
                                            {appSetup?.platform_charges}
                                          </span>
                                        </div>
                                        <hr></hr>
                                        <div className="text-holder">
                                          <h4>Total:-</h4>
                                          <span>
                                            ₹{" "}
                                            {checked == true
                                              ? totalCharge +
                                                appSetup?.platform_charges
                                              : data?.half_day_charges *
                                                  useDays +
                                                appSetup?.platform_charges}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                    {useRoundedHours ===
                                      data?.full_day_hours && (
                                      <div className="Bifurcation_content-holder">
                                        <div className="text-holder">
                                          <h4>Days:-</h4>
                                          <span>{useDays}</span>
                                        </div>

                                        <div className="text-holder">
                                          <h4>Full Day Charges:-</h4>
                                          <span>
                                            ₹ {data?.full_day_charges}
                                          </span>
                                        </div>

                                        <div className="text-holder">
                                          <h4>Half Day Charges:-</h4>
                                          <span>-</span>
                                        </div>

                                        <div className="text-holder">
                                          <h4>Hourly Basis Charges:-</h4>
                                          <span>-</span>
                                        </div>
                                        {
                                          <div className="text-holder">
                                            <h4>Add On Charges:</h4>
                                            <span>
                                              {checkedAddOns
                                                .map((id) => {
                                                  const addOn = data?.studio_add_ons.find(
                                                    (item) => item.id === id
                                                  );
                                                  return addOn?.rate;
                                                })
                                                .join(" + ")}{" "}
                                              {totalAddOnCharges}
                                            </span>
                                          </div>
                                        }
                                        <div className="text-holder">
                                          <h4> PlatFrom Charges :-</h4>
                                          <span>
                                            {appSetup?.platform_charges}
                                          </span>
                                        </div>
                                        <hr></hr>
                                        <div className="text-holder">
                                          <h4>Total:-</h4>
                                          <span>
                                            ₹{" "}
                                            {checked == true
                                              ? totalCharge +
                                                appSetup?.platform_charges
                                              : data?.full_day_charges *
                                                  useDays +
                                                appSetup?.platform_charges}
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                    {useRoundedHours > data?.half_day_hours &&
                                      useRoundedHours <
                                        data?.full_day_hours && (
                                        <div className="Bifurcation_content-holder">
                                          <div className="text-holder">
                                            <h4>Days:-</h4>
                                            <span>{useDays}</span>
                                          </div>

                                          <div className="text-holder">
                                            <h4>Full Day Charges:-</h4>
                                            <span>-</span>
                                          </div>

                                          <div className="text-holder">
                                            <h4>Half Day Charges:-</h4>
                                            <span>
                                              ₹ {data?.half_day_charges}
                                            </span>
                                          </div>

                                          <div className="text-holder">
                                            <h4>
                                              Hourly Basis Charges x{" "}
                                              {useRoundedHours -
                                                data?.half_day_hours}
                                              : -
                                            </h4>
                                            <span>
                                              ₹ {data?.hourly_basis_charges}
                                            </span>
                                          </div>
                                          {
                                            <div className="text-holder">
                                              <h4>Add On Charges:</h4>
                                              <span>
                                                {checkedAddOns
                                                  .map((id) => {
                                                    const addOn = data?.studio_add_ons.find(
                                                      (item) => item.id === id
                                                    );
                                                    return addOn?.rate;
                                                  })
                                                  .join(" + ")}{" "}
                                                {totalAddOnCharges}
                                              </span>
                                            </div>
                                          }
                                          <div className="text-holder">
                                            <h4> PlatFrom Charges :-</h4>
                                            <span>
                                              {appSetup?.platform_charges}
                                            </span>
                                          </div>
                                          <hr></hr>
                                          <div className="text-holder">
                                            <h4>Total:-</h4>
                                            <span>
                                              ₹{" "}
                                              {checked == true
                                                ? totalCharge +
                                                  appSetup?.platform_charges
                                                : (data?.half_day_charges +
                                                    data?.hourly_basis_charges *
                                                      (useRoundedHours -
                                                        data?.half_day_hours)) *
                                                    useDays +
                                                  appSetup?.platform_charges}{" "}
                                            </span>
                                          </div>
                                        </div>
                                      )}

                                    {useRoundedHours > data?.minimum_hours &&
                                      useRoundedHours <
                                        data?.half_day_hours && (
                                        <div className="Bifurcation_content-holder">
                                          <div className="text-holder">
                                            <h4>Days:-</h4>
                                            <span>{useDays}</span>
                                          </div>

                                          <div className="text-holder">
                                            <h4>Full Day Charges:-</h4>
                                            <span>-</span>
                                          </div>

                                          <div className="text-holder">
                                            <h4>Half Day Charges:-</h4>
                                            <span>-</span>
                                          </div>

                                          <div className="text-holder">
                                            <h4>
                                              Hourly Basis Charges x{" "}
                                              {useRoundedHours
                                                ? useRoundedHours
                                                : 0}
                                              :-
                                            </h4>
                                            <span>
                                              ₹ {data?.hourly_basis_charges}
                                            </span>
                                          </div>
                                          {
                                            <div className="text-holder">
                                              <h4>Add On Charges:</h4>
                                              <span>
                                                {checkedAddOns
                                                  .map((id) => {
                                                    const addOn = data?.studio_add_ons.find(
                                                      (item) => item.id === id
                                                    );
                                                    return addOn?.rate;
                                                  })
                                                  .join(" + ")}{" "}
                                                {totalAddOnCharges}
                                              </span>
                                            </div>
                                          }
                                          <div className="text-holder">
                                            <h4> PlatFrom Charges :-</h4>
                                            <span>
                                              {appSetup?.platform_charges}
                                            </span>
                                          </div>
                                          <hr></hr>
                                          <div className="text-holder">
                                            <h4>Total:-</h4>
                                            <span>
                                              ₹{" "}
                                              {checked == true
                                                ? totalCharge +
                                                  appSetup?.platform_charges
                                                : data?.hourly_basis_charges *
                                                    useRoundedHours *
                                                    useDays +
                                                  appSetup?.platform_charges}{" "}
                                            </span>
                                          </div>
                                        </div>
                                      )}

                                    {useRoundedHours ===
                                      data?.minimum_hours && (
                                      <div className="Bifurcation_content-holder">
                                        <div className="text-holder">
                                          <h4>Days:-</h4>
                                          <span>{useDays}</span>
                                        </div>

                                        <div className="text-holder">
                                          <h4>Full Day Charges:-</h4>
                                          <span>-</span>
                                        </div>

                                        <div className="text-holder">
                                          <h4>Half Day Charges:-</h4>
                                          <span>-</span>
                                        </div>

                                        <div className="text-holder">
                                          <h4>
                                            Hourly Basis Charges x{" "}
                                            {useRoundedHours
                                              ? useRoundedHours
                                              : 0}{" "}
                                            :-
                                          </h4>
                                          <span>
                                            ₹ {data?.hourly_basis_charges}
                                          </span>
                                        </div>

                                        {
                                          <div className="text-holder">
                                            <h4>Add On Charges:</h4>
                                            <span>
                                              {checkedAddOns
                                                .map((id) => {
                                                  const addOn = data?.studio_add_ons.find(
                                                    (item) => item.id === id
                                                  );
                                                  return addOn?.rate;
                                                })
                                                .join(" + ")}{" "}
                                              {totalAddOnCharges}
                                            </span>
                                          </div>
                                        }
                                        <div className="text-holder">
                                          <h4> PlatFrom Charges :-</h4>
                                          <span>
                                            {appSetup?.platform_charges}
                                          </span>
                                        </div>
                                        <hr></hr>
                                        <div className="text-holder">
                                          <h4>Total:-</h4>
                                          <span>
                                            ₹{" "}
                                            {checked == true
                                              ? totalCharge +
                                                appSetup?.platform_charges
                                              : data?.hourly_basis_charges *
                                                  useRoundedHours *
                                                  useDays +
                                                appSetup?.platform_charges}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </Form>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>

              <div className="text-center mt-5">
                <buttton
                  className="proceed-btn"
                  onClick={handleSubmit(onSubmit)}
                  // onClick={() => setModalShow(true)}
                >
                  Proceed to Pay
                </buttton>
              </div>
            </Form>
          </div>
        </div>
      </div>

      <Success_modal
        maintext={"Booking Successfully"}
        smalltext={"Soon you will be notified by email & sms."}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default Daily_form;
