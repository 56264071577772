import React, { useEffect, useRef, useState, useContext } from "react";
import "./meet-team.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { TeamAbout } from "../../../utils/apis/AboutUs/AboutUs";
import parse from "html-react-parser";
const Meet_team = () => {
  const [data, setData] = useState();
  const { IMG_URL } = useContext(Context);
  const getAllData = async () => {
    const res = await TeamAbout();
    if (res?.success) {
      setData(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);
  return (
    <>
      <section className="Meet_team_section ">
        <div className="container">
          <div className="meetteambg">
            <div className="row">
              <div className="col-xl-7 mx-auto">
                <div className="title-div">
                  <p className="subt">MEET OUR</p>
                  <h1 className="team-title">Team</h1>
                  <p className="subt2">
                    There is no denying fact, that the success lies in reaching
                    out
                  </p>

                  <div className="bonds-card-main">
                    <h1 className="bonds-title">BONDS</h1>

                    <div className="colapsediv">
                      <div className="row ms-0 me-0">
                        {data?.map(
                          (val, index) =>
                            index === 0 && (
                              <div className="col-md-4  col-sm-4 space1">
                                <div className="teamcard">
                                  <img
                                    className="person"
                                    src={IMG_URL + val?.image}
                                  />

                                  <div className="black-overlay"></div>
                                  <div className="overlay-content">
                                    <h6 className="name">{val?.name}</h6>
                                    <p className="designation">
                                      {val?.designation}
                                    </p>

                                    <div>
                                      {val?.our_team_social_media?.map(
                                        (data) => (
                                          <a
                                            href={data?.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <img
                                              className="socialiccc"
                                              src={
                                                IMG_URL +
                                                data?.social_medium?.logo
                                              }
                                              alt="Social Media Icon"
                                            />
                                          </a>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                        )}
                        {data?.map(
                          (val, index) =>
                            index === 1 && (
                              <div className="col-md-4  col-sm-4 space1 space2 ">
                                <div className="teamcard">
                                  <img
                                    className="person"
                                    src={IMG_URL + val?.image}
                                  />
                                  <bd className="bd1"></bd>
                                  <bd className="bd2"></bd>
                                  <div className="black-overlay"></div>
                                  <div className="overlay-content">
                                    <h6 className="name">{val?.name}</h6>
                                    <p className="designation">
                                      {val?.designation}
                                    </p>

                                    <div className="d-flex">
                                      <div>
                                        {val?.our_team_social_media?.map(
                                          (data) => (
                                            <a
                                              href={data?.link}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              <img
                                                className="socialiccc"
                                                src={
                                                  IMG_URL +
                                                  data?.social_medium?.logo
                                                }
                                                alt="Social Media Icon"
                                              />
                                            </a>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                        )}
                        {data?.map(
                          (val, index) =>
                            index === 2 && (
                              <div className="col-md-4  col-sm-4 space1">
                                <div className="teamcard">
                                  <img
                                    className="person"
                                    src={IMG_URL + val?.image}
                                  />

                                  <div className="black-overlay"></div>
                                  <div className="overlay-content">
                                    <h6 className="name">{val?.name}</h6>
                                    <p className="designation">
                                      {val?.designation}
                                    </p>

                                    <div>
                                      {val?.our_team_social_media?.map(
                                        (data) => (
                                          <a
                                            href={data?.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <img
                                              className="socialiccc"
                                              src={
                                                IMG_URL +
                                                data?.social_medium?.logo
                                              }
                                              alt="Social Media Icon"
                                            />
                                          </a>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Meet_team;
