import React, { useState } from "react";
import PropTypes from "prop-types"; // Add PropTypes for type checking
import "./FilterModal.css";
import { Button, Col, Nav, Row, Tab } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FilterModal = ({ setLocation, setPrice, setRating, ...props }) => {
  const [value, setValue] = useState({ min: 0, max: 20000 });
  const [isOpen, setIsOpen] = useState(false);
  const [location, setLocationState] = useState("");
  const [price, setPriceState] = useState("");
  const [rating, setRatingState] = useState("");

  const minValue = 0;
  const maxValue = 20000;

  const handleSearch = () => {
    setLocation({ location, distanceRange: value });
  };

  const handleClear = () => {
    setLocationState("");
    setPriceState("");
    setRating("");
    setPrice({ price: "", priceRange: "" });
    setLocation({
      location: "",
      distanceRange: { min: minValue, max: maxValue },
    });
    setValue({ min: minValue, max: maxValue });
  };

  const handleSearchPrice = () => {
    setPrice({ price, priceRange: value });
  };

  const handleClearPrice = () => {
    setPriceState("");
    setPrice({ price: "", priceRange: "" });
  };

  const handleSearchRating = () => {
    setRating({ rating });
  };

  const handleClearRatings = () => {
    setRating("");
    setPriceState("");
    setLocation({
      location: "",
      distanceRange: { min: minValue, max: maxValue },
    });
    setRatingState("");
    setPrice({ price: "", priceRange: "" });
  };

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="filter-modal"
    >
      <Modal.Header closeButton>
        {/* <Modal.Title id="contained-modal-title-vcenter">Filter</Modal.Title>/ */}
      </Modal.Header>
      <Modal.Body>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row>
            <Col lg={3} md={4} className="pe-lg-0 pe-md-0 pe-sm-unset pe-unset">
              <div className="bg-color">
                <div className="heading-name mb-4">
                  <h2>Sort By</h2>
                </div>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="first">Location</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second" className="border-line">
                      Pricing
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="third" className="border-line">
                      Ratings
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            </Col>
            <Col lg={9} md={8} className="ps-lg-0 ps-md-0 ps-sm-unset ps-unset">
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <div className="location-bg">
                    <Row>
                      <Col lg={5}>
                        <Form>
                          <Form.Group
                            className="mb-3"
                            controlId="locationInput"
                          >
                            <Form.Label>Desired Location</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter your desired location"
                              value={location}
                              onChange={(e) => setLocationState(e.target.value)}
                            />
                          </Form.Group>
                          <div className="btn-holder">
                            <Button
                              className="btn-search"
                              onClick={handleSearch}
                            >
                              SEARCH
                            </Button>
                            <Button className="btn-clear" onClick={handleClear}>
                              CLEAR
                            </Button>
                          </div>
                        </Form>
                      </Col>
                      {/* <Col lg={2}>
                        <div className="circle-orr text-center">
                          <p>OR</p>
                        </div>
                      </Col> */}
                      {/* <Col lg={5}>
                        <div className="distance-range">
                          <p>Select Distance Range (kms)</p>
                          <div className="range-bar">
                            <InputRange
                              draggableTrack
                              minValue={minValue}
                              maxValue={maxValue}
                              value={value}
                              onChange={setValue}
                            />
                            <div className="slider-value">
                              <div className="slide">
                                <span className="text">{minValue}</span>
                                <span className="text">{maxValue}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col> */}
                    </Row>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <div className="location-bg-second">
                    <Row>
                      <Col lg={5}>
                        <Form>
                          <Form.Group className="mb-3" controlId="priceInput">
                            <Form.Label>Desired Budget</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Enter your desired Price"
                              value={price}
                              onChange={(e) => setPriceState(e.target.value)}
                            />
                          </Form.Group>
                          <div className="btn-holder">
                            <Button
                              className="btn-search"
                              onClick={handleSearchPrice}
                            >
                              SEARCH
                            </Button>
                            <Button
                              className="btn-clear"
                              onClick={handleClearPrice}
                            >
                              CLEAR
                            </Button>
                          </div>
                        </Form>
                      </Col>
                      <Col lg={2}>
                        <div className="circle-orr text-center">
                          <p>OR</p>
                        </div>
                      </Col>
                      <Col lg={5}>
                        <div className="distance-range">
                          <p>Select Budget Range</p>
                          <div className="range-bar">
                            <InputRange
                              draggableTrack
                              minValue={minValue}
                              maxValue={maxValue}
                              value={value}
                              onChange={(value) => {
                                setValue(value);
                                setPrice({ price, priceRange: value });
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <div className="location-bg-third">
                    <Form>
                      <Row className="mb-3">
                        <p>Select Ratings</p>
                        {[1, 2, 3, 4, 5].map((ratingValue) => (
                          <Form.Group
                            as={Col}
                            lg={4}
                            key={ratingValue}
                            className="mb-3 d-flex align-items-center"
                          >
                            <div className="d-flex align-items-center">
                              <Form.Check
                                type="radio"
                                name="rating"
                                id={`ratingRadio${ratingValue}`}
                                value={ratingValue}
                                checked={rating === ratingValue.toString()}
                                onChange={(e) => {
                                  setRatingState(e.target.value);
                                  setRating({ rating: e.target.value });
                                }}
                                className="me-2"
                              />
                              <FontAwesomeIcon
                                icon={faStar}
                                className="star-icon"
                              />
                              <span>{ratingValue}</span>
                            </div>
                          </Form.Group>
                        ))}
                      </Row>
                      <div className="btn-holder">
                        <Button
                          className="btn-search"
                          onClick={handleSearchRating}
                        >
                          SEARCH
                        </Button>
                        <Button
                          className="btn-clear"
                          onClick={handleClearRatings}
                        >
                          CLEAR
                        </Button>
                      </div>
                    </Form>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Modal.Body>
    </Modal>
  );
};

FilterModal.propTypes = {
  setLocation: PropTypes.func.isRequired,
  setPrice: PropTypes.func.isRequired,
};

export default FilterModal;
