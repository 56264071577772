import React, { useContext, useState, Component } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import { RegxExpression } from "../../utils/common";
import { CheckExist, emailOtpGet } from "../../utils/apis/myAccount/myAccount";
import "./loginform.css";
import Form from "react-bootstrap/Form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faHouse } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";

import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";

import { useNavigate } from "react-router-dom";
import { Context } from "../../utils/context";
import Cookies from "js-cookie";
import OtpInput from "react-otp-input";
import ReactDOM from "react-dom";
import Countdown from "react-countdown";
import OtpTimer from "otp-timer";

import { userLogin } from "../../utils/apis/login/Login";
const Forgot_password = () => {
  const [phoneValue, setPhoneValue] = useState("");
  const [otp, setOtp] = useState("");
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();
  const [newpass, setNewpass] = useState(true);
  const [resetModal, setResetmodal] = useState(false);
  const resetClose = () => {
    setResetmodal(true);
    setTimeout(() => {
      setResetmodal(false);
      window.location = "/login";
    }, 3000);
  };

  const [modalShow, setModalShow] = useState(false);
  const [showSetNewPassword, setShowSetNewPassword] = useState(false);
  const [showCheckYourEmail, setShowCheckYourEmail] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const { signin, setSignin, shimmerLoader, setShimmerLoader } = useContext(
    Context
  );

  // const handleSubmit = () => {
  //   setShowCheckYourEmail(true);
  //   setTimeout(() => {
  //     setShowCheckYourEmail(false);
  //     setShowSetNewPassword(true);
  //   }, 3000); // Wait for 3 seconds before showing Set_New_Password
  // };

  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    setShimmerLoader(true);
    const res = await CheckExist(data);
    if (res?.success) {
      setNewpass(false);
      //   emailOtpGet(data)
      setEmail(data?.email);
      navigate("/set-password");
    } else {
      setShimmerLoader(false);
      setError("email", { message: "User Not Found With This E-mail" });
      // setError('contact_no', { message: "" });
    }
  };

  return (
    <>
      <section className="login-page-main-sec">
        <div className="container">
          <div className="main-position-div">
            <div className="d-flex aligndiv">
              <div className="mid-img-div">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/login/mid-img2.png"
                  }
                />
              </div>

              <div className="last-div slidein margin-top">
                <div className="loginformsec topspace">
                  <Link to="/login">
                    <FontAwesomeIcon
                      className="home-icn"
                      icon={faChevronLeft}
                    />
                  </Link>
                  <h1 className="login-title">Reset Your Password</h1>
                  <p className="loginby">
                    You will receive an email from us requesting you to reset
                    your password.
                  </p>

                  <div className="form-div ">
                    <Form>
                      <Form.Group className="form-group resetpassformtop">
                        <Form.Label>Email or Mobile Number</Form.Label>
                        <Form.Control
                          type="text"
                          name="email_or_contact"
                          placeholder="Email / Mobile Number"
                          {...register("email_or_contact", {
                            required: "Credentials required",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.email_or_contact,
                            "is-valid": getValues("email_or_contact"),
                          })}
                        />
                        <button type="button" className="sendotpbtn">
                          Send Otp
                        </button>
                      </Form.Group>

                      <Form.Group className="form-group otpinputmain">
                        <OtpInput
                          value={otp}
                          onChange={setOtp}
                          numInputs={4}
                          renderSeparator={<span>-</span>}
                          renderInput={(props) => <input {...props} />}
                          className="me-2"
                        />

                        <OtpTimer seconds={60} minutes={0} />
                      </Form.Group>

                      {/* <button className='Login-btn resetpasstop'>Submit</button> */}
                      <Link to="/set-password">
                        <button
                          type="button"
                          className="Login-btn resetpasstop"
                        >
                          Submit
                        </button>
                      </Link>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Forgot_password;
