import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import "./Faq.css";
import { Link } from "react-router-dom";
import { Context } from "../../utils/context";
import { FAQdata } from "../../utils/apis/master/Master";
import parse from "html-react-parser";
const faqData = [
  {
    eventKey: "0",
    header: "How can I change my personal information?",
    body: (
      <>
        <p> To change your personal information follow these general steps.</p>
        <p>
          {" "}
          Log In: Go to the e-commerce website and log in to your account using
          your username/email and password.
        </p>
        <p>
          {" "}
          Access Account Settings: Look for a section labeled "Account
          Settings," "Profile," or something similar. This is usually located
          either at the top right corner (next to your name) or in a dropdown
          menu under your username.
        </p>
        <p>
          {" "}
          Navigate to Personal Information: Within the account settings or
          profile section, there should be an option to edit your personal
          information. This typically includes details like your name, shipping
          address, email address, and phone number.
        </p>
        <p>
          {" "}
          Make Changes: Click on the edit or update button next to the
          information you want to change. Some websites might require you to
          verify your current password for security purposes before allowing
          changes.
        </p>
        <p>
          {" "}
          Save Changes: After making the necessary edits, save your changes by
          clicking on a "Save," "Update," or "Submit" button.
        </p>
        <p>
          {" "}
          Confirmation: Depending on the website, you might receive a
          confirmation message or email indicating that your changes have been
          successfully updated.
        </p>
        <p>
          {" "}
          Review: It's a good practice to review your updated information to
          ensure everything is correct.
        </p>
        <p>
          {" "}
          If you encounter any issues or if the website's layout is different,
          look for a help section or contact customer support for assistance.
          They can guide you through the process specific to their platform.
        </p>
      </>
    ),
  },
  {
    eventKey: "1",
    header: "How to Create Account?",
    body: (
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </p>
    ),
  },
  {
    eventKey: "2",
    header: "How to Book Studio?",
    body: (
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </p>
    ),
  },
];

const Faq = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);
  const [data, setData] = useState();

  const getAllData = async () => {
    const res = await FAQdata();
    if (res?.success) {
      setData(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);
  return (
    <>
      <section className="faq">
        <section className="About_banner_sec">
          <div className="container-fluid p-0">
            <img
              className="banner-img"
              src={
                process.env.PUBLIC_URL + "/assets/images/subscription/FAQs.png"
              }
              alt="FAQs banner"
            />
            <div className="overlayblack"></div>
            <div className="overlay-text">
              <h3 className="banner-title">Queries on mind?</h3>
              <h6 className="subtitle">We will gladly help you</h6>
            </div>

            <div className="home-return-pos">
              <div className="container">
                <Link to="/">Home</Link>
              </div>
            </div>
          </div>
        </section>

        <Container>
          <div className="background-card">
            <img
              className="curve-img"
              src={
                process.env.PUBLIC_URL +
                "/assets/images/about-us/banner/layer.png"
              }
              alt="Curve"
            />
            {data?.map((faq, index) => (
              <Accordion defaultActiveKey={data[0]?.id} key={index}>
                <Accordion.Item eventKey={faq?.id}>
                  <Accordion.Header>{faq?.name}</Accordion.Header>
                  <Accordion.Body>{parse(faq?.description)}</Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ))}
          </div>
        </Container>
      </section>
    </>
  );
};

export default Faq;
