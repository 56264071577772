import React, { useState, useEffect, useContext } from "react";
import "./loginform.css";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { useLocation } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { RegxExpression } from "../../utils/common";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import PasswordChecklist from "react-password-checklist";
import { Context } from "../../utils/context";
import Cookies from "js-cookie";
import { userRegister } from "../../utils/apis/login/Login";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import RegisterModal from "../common-section/Modals/RegisterModal/RegisterModal";
import Select from "react-select";

const Signup = () => {
  const location = useLocation();
  const message = location?.search;

  console.log(message, "message");

  const { postData, getData, Select2Data } = useContext(Context);
  const [phoneValue, setPhoneValue] = useState("");

  const [passwordVisible, setPasswordVisible] = useState(false);
  // const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  // const togglePasswordVisibility = () => {
  //     setPasswordVisible(!passwordVisible);
  // };
  // const toggleConfirmPasswordVisibility = () => {
  //     setConfirmPasswordVisible(!confirmPasswordVisible);
  // };
  const [type, setType] = useState("");
  const [passwordVisible2, setPasswordVisible2] = useState(false);
  const [confirmPasswordVisible2, setConfirmPasswordVisible2] = useState(false);
  const togglePasswordVisibility2 = () => {
    setPasswordVisible2(!passwordVisible2);
  };
  const toggleConfirmPasswordVisibility2 = () => {
    setConfirmPasswordVisible2(!confirmPasswordVisible2);
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordOne, setShowPasswordOne] = useState(false);
  const [iconOne, setIconOne] = useState(faEyeSlash);
  const [iconTwo, setIconTwo] = useState(faEyeSlash);

  const navigate = useNavigate();

  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
      setIconOne(showPassword ? faEyeSlash : faEye);
    } else if (field === "reenterPassword") {
      setShowPasswordOne(!showPasswordOne);
      setIconTwo(showPasswordOne ? faEyeSlash : faEye);
    }
  };

  const { signin, setSignin, shimmerLoader, setShimmerLoader } = useContext(
    Context
  );

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  const [modalShow, setModalShow] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  console.log(isChecked, "isCheckedisCheckedisChecked");

  const [emailError, setEmailError] = useState();

  const onSubmit = async (data) => {
    const finalData = new FormData();
    finalData.append("type", type);
    finalData.append("first_name", data?.first_name);
    finalData.append("last_name", data?.last_name);

    if (data?.address) {
      finalData.append("address", data?.address);
    }

    finalData.append("termsAndConditions", data?.termsAndConditions);

    finalData.append("email", data?.email);
    finalData.append("contact_no", data?.contact_no);

    if (data?.alt_contact_no) {
      finalData.append("alt_contact_no", data?.alt_contact_no);
    }

    finalData.append("password", data?.password);

    if (data?.country_id?.value) {
      finalData.append("country_id", data?.country_id?.value);
    }

    if (data?.city_id?.value) {
      finalData.append("city_id", data?.city_id?.value);
    }

    if (data?.state_id?.value) {
      finalData.append("state_id", data?.state_id?.value);
    }

    if (data?.pincode_id?.value) {
      finalData.append("pincode_id", data?.pincode_id?.value);
    }

    setShimmerLoader(true);
    if (password === passwordAgain) {
      const res = await userRegister(finalData);
      if (res?.success) {
        if(type == "Customer"){
          await Cookies.set("flick_N_shoot_security", res?.data?.token);
          setSignin(true);
        }
       
        setModalShow(true);
        setEmailError();
        setTimeout(() => {
          setModalShow(false);
          setShimmerLoader(false);
          navigate("/");
        }, 3000);
      } else {
        setShimmerLoader(false);
        // Handle errors

        if (res?.message?.email) {
          setError("email", { type: "manual", message: res.message?.email });
        }
        if (res?.message?.contact_no) {
          setError("contact_no", {
            type: "manual",
            message: res.message?.contact_no,
          });
        }
      }
    } else {
      setError("re_password", { message: "Password Does not match" });
    }
  };

  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");

  const [country, setCountry] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [pincode, setPincode] = useState([]);
 
  const [showError, setShowError] = useState(false);

  console.log(type);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
    if (e.target.checked) {
      setShowError(false);
    }
  };

  // {
  //   message ? setType("Vendor") : setType("Customer");
  // }

  useEffect(() => {
    if (message) {
      setType("Vendor");
    } else {
      setType("Customer");
    }
  }, []);
 console.log(message,"message");
 
  const GetAllCountries = async () => {
    const response = await getData("/without-login/allcountry");

    if (response?.success) {
      setCountry(await Select2Data(response?.data, "country_id"));
    }
  };

  const GetAllStates = async (id) => {
    const response = await getData(`/without-login/allstate/${id}`);

    if (response?.success) {
      setStates(await Select2Data(response?.data, "state_id"));
    }
  };

  const GetAllCities = async (id) => {
    const response = await getData(`/without-login/allcity/${id}`);

    if (response?.success) {
      setCities(await Select2Data(response?.data, "city_id"));
    }
  };

  const GetAllPincode = async (id) => {
    const response = await getData(`/without-login/allpincodes/${id}`);

    if (response?.success) {
      setPincode(await Select2Data(response?.data, "pincode_id"));
    }
  };

  useEffect(() => {
    GetAllCountries();
    GetAllStates();
    GetAllCities();
    GetAllPincode();
  }, []);

  return (
    <>
      <section className="login-page-main-sec">
        <div className="container">
          <div className="main-position-div">
            <div className="d-flex aligndiv">
              {/* dont integrate this form  */}
              <Link to="/login" className="formaadeco">
                <div className="first-div slidein2 fadediv margin-top">
                  <div className="loginformsec">
                    <Link to="/">
                      <FontAwesomeIcon className="home-icn" icon={faHouse} />
                    </Link>
                    <h1 className="login-title">Log In</h1>
                    <p className="title-sub">
                      to access your dashboard & manage bookings securely.
                    </p>

                    <div className="form-div">
                      <Form>
                        <Form.Group className="form-group">
                          <Form.Label>Email or Mobile Number</Form.Label>
                          <Form.Control
                            disabled
                            type="text"
                            placeholder="Enter Email or Mobile Number"
                          />
                        </Form.Group>
                        <Form.Group className="form-group">
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            disabled
                            type="text"
                            placeholder="Enter Password"
                          />
                          <FontAwesomeIcon className="eye-icc" icon={faEye} />
                        </Form.Group>

                        <Form.Group className="form-group text-end">
                          <Link className="forgo-pass" to="/forgot-password">
                            Forgot Password?
                          </Link>
                        </Form.Group>

                        <button disabled className="Login-btn">
                          Log In
                        </button>
                      </Form>
                    </div>

                    <p className="lastcont">
                      <Link>New here?</Link>Sign up for free to unlock exclusive
                      discounts, faster bookings, and join a vibrant community
                      of creatives.{" "}
                    </p>

                    <div className="d-flex">
                      <div className="my-auto">
                        <p className="loginby">
                          You can also login by using your
                        </p>
                      </div>
                      <Link to="/">
                        <img
                          className="socialicon"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/icons/google.png"
                          }
                        />
                      </Link>

                      <Link to="/">
                        <img
                          className="socialicon"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/icons/twitter.png"
                          }
                        />
                      </Link>

                      <Link to="/">
                        <img
                          className="socialicon"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/icons/facebook.png"
                          }
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </Link>
              {/* dont integrate this form  */}

              <div className="mid-img-div">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/login/mid-img.png"
                  }
                />
              </div>

              {/* this is sign up form  */}
              <div className="last-div slidein ">
                <div className="loginformsec">
                  <Link to="/">
                    <FontAwesomeIcon className="home-icn" icon={faHouse} />
                  </Link>
                  <img
                    className="loglogo"
                    src={
                      process.env.PUBLIC_URL + "/assets/images/logo/logo.png"
                    }
                  />
                  <h1 className="login-title">Sign Up</h1>
                  <Form>
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="first"
                    >
                      <Row>
                        <Tab.Content>
                          <Tab.Pane eventKey="first">
                            <div className="form-div">
                              <div className="login-with-option-holder mb-2">
                                <Form.Group
                                  className="mb-2 login-with-heading"
                                  controlId=""
                                >
                                  <Form.Label>
                                    Register With{" "}
                                    <b>{message ? message : "Customer"}</b>
                                  </Form.Label>
                                </Form.Group>
                                {/* <Form.Check
                                  inline
                                  label="Customer"
                                  name="type"
                                  type="radio"
                                  value="customer"
                                  {...register("type", {
                                    required: "Select an option",
                                  })}
                                  onChange={() => setType("customer")} // Set the type to "customer"
                                  checked={type === "customer"} // Ensure the correct radio button is checked
                                />

                                <Form.Check
                                  inline
                                  label="Vendor"
                                  name="type"
                                  type="radio"
                                  value="vendor"
                                  {...register("type", {
                                    required: "Select an option",
                                  })}
                                  onChange={() => setType("vendor")} // Set the type to "vendor"
                                  checked={type === "vendor"} // Ensure the correct radio button is checked
                                /> */}

                                {errors.type && (
                                  <div className="invalid-feedback d-block">
                                    {errors.type.message}
                                  </div>
                                )}
                              </div>

                              <Form.Group className="form-group">
                                <Form.Label>Enter First Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="first_name"
                                  placeholder="Enter First Name"
                                  {...register("first_name", {
                                    required: "First name required",
                                  })}
                                  className={classNames("", {
                                    "is-invalid": errors?.first_name,
                                    "is-valid": getValues("first_name"),
                                  })}
                                  onKeyDown={(event) => {
                                    if (!RegxExpression.name.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                              </Form.Group>

                              <Form.Group className="form-group">
                                <Form.Label>Enter Last Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="last_name"
                                  placeholder="Enter First Name"
                                  {...register("last_name", {
                                    required: "Last name required",
                                  })}
                                  className={classNames("", {
                                    "is-invalid": errors?.last_name,
                                    "is-valid": getValues("last_name"),
                                  })}
                                  onKeyDown={(event) => {
                                    if (!RegxExpression.name.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                              </Form.Group>

                              <Form.Group className="form-group">
                                <Form.Label>Enter Email</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="email"
                                  placeholder="Enter Email"
                                  {...register("email", {
                                    required: "Email is required",
                                    pattern: {
                                      value: RegxExpression.email,
                                      message: "Invalid email ",
                                    },
                                  })}
                                  className={classNames("", {
                                    "is-invalid": errors?.email,
                                    "is-valid": getValues("email"),
                                  })}
                                />
                                {errors?.email && (
                                  <sup className="text-danger">
                                    {errors?.email?.message}
                                  </sup>
                                )}
                              </Form.Group>

                              {type == "Vendor" && (
                                <Form.Group className="form-group">
                                  <Form.Label>Address</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="address"
                                    placeholder="Enter Address"
                                    {...register("address", {
                                      required: "Address required",
                                    })}
                                    className={classNames("", {
                                      "is-invalid": errors?.address,
                                      "is-valid": getValues("address"),
                                    })}
                                    // onKeyDown={(event) => {
                                    //   if (
                                    //     !RegxExpression.name.test(event.key)
                                    //   ) {
                                    //     event.preventDefault();
                                    //   }
                                    // }}
                                  />
                                </Form.Group>
                              )}

                              <Form.Group className="form-group">
                                <Form.Label>Enter Mobile Number</Form.Label>
                                <Controller
                                  name="contact_no"
                                  control={control}
                                  rules={{
                                    required: "Contact number is required",
                                    minLength: {
                                      value: 10,
                                      message:
                                        "Number should be at least 10 characters",
                                    },
                                  }}
                                  render={({ field }) => (
                                    <Form.Control
                                      {...field}
                                      className={classNames("", {
                                        "is-invalid": errors?.contact_no,
                                        "is-valid": getValues("contact_no"),
                                      })}
                                      onChange={(value) =>
                                        field.onChange(value)
                                      }
                                      type="text"
                                      placeholder="Enter Mobile Number"
                                      onKeyDown={(event) => {
                                        if (
                                          !RegxExpression.phone.test(event.key)
                                        ) {
                                          if (event.key !== "Backspace") {
                                            event.preventDefault();
                                          }
                                        }
                                      }}
                                      maxLength={10}
                                    />
                                  )}
                                />
                                {errors?.contact_no && (
                                  <sup className="text-danger">
                                    {errors?.contact_no?.message}
                                  </sup>
                                )}
                              </Form.Group>

                              {type == "Vendor" && (
                                <Form.Group className="form-group">
                                  <Form.Label>
                                    Enter Alternate Mobile Number
                                  </Form.Label>
                                  <Controller
                                    name="alt_contact_no"
                                    control={control}
                                    rules={{
                                      minLength: {
                                        value: 10,
                                        message:
                                          "Number should be at least 10 characters",
                                      },
                                    }}
                                    render={({ field }) => (
                                      <Form.Control
                                        {...field}
                                        className={classNames("", {
                                          "is-invalid": errors?.alt_contact_no,
                                          "is-valid": getValues(
                                            "alt_contact_no"
                                          ),
                                        })}
                                        onChange={(value) =>
                                          field.onChange(value)
                                        }
                                        type="text"
                                        placeholder="Enter Alternate Mobile Number"
                                        onKeyDown={(event) => {
                                          if (
                                            !RegxExpression.phone.test(
                                              event.key
                                            )
                                          ) {
                                            if (event.key !== "Backspace") {
                                              event.preventDefault();
                                            }
                                          }
                                        }}
                                        maxLength={10}
                                      />
                                    )}
                                  />
                                  {errors?.alt_contact_no && (
                                    <sup className="text-danger">
                                      {errors?.alt_contact_no?.message}
                                    </sup>
                                  )}
                                </Form.Group>
                              )}
                              <div className="row">
                                {type == "Vendor" && (
                                  <Col xl={6} xs={12}>
                                    <div className="main-form-section mt-3">
                                      <Row className="row justify-content-center mb-2">
                                        <Form.Label>Country</Form.Label>

                                        <Controller
                                          name="country_id" // name of the field
                                          {...register("country_id", {
                                            required: "Select Country",
                                          })}
                                          control={control}
                                          render={({ field }) => (
                                            <Select
                                              styles={{
                                                control: (baseStyles) => ({
                                                  ...baseStyles,
                                                  borderColor: errors.country_id
                                                    ? "red"
                                                    : baseStyles,
                                                }),
                                              }}
                                              {...field}
                                              options={country}
                                              onChange={(selectedOption) => {
                                                field.onChange(
                                                  selectedOption.value
                                                ); // Update Controller's value
                                                GetAllStates(
                                                  selectedOption.value
                                                );
                                                setValue(
                                                  "country_id",
                                                  selectedOption
                                                );
                                                setValue("state_id", null);
                                                setValue("city_id", null);
                                                setValue("pincode_id", null);
                                              }}
                                            />
                                          )}
                                        />

                                        {errors.country_id && (
                                          <span className="text-danger">
                                            {errors.country_id.message}
                                          </span>
                                        )}
                                      </Row>
                                    </div>
                                  </Col>
                                )}

                                {type == "Vendor" && (
                                  <Col xl={6} xs={12}>
                                    <div className="main-form-section mt-3">
                                      <Row className="row justify-content-center mb-2">
                                        <Form.Label>State</Form.Label>

                                        <Controller
                                          name="state_id" // name of the field
                                          {...register("state_id", {
                                            required: "Select State",
                                          })}
                                          control={control}
                                          render={({ field }) => (
                                            <Select
                                              styles={{
                                                control: (baseStyles) => ({
                                                  ...baseStyles,
                                                  borderColor: errors.state_id
                                                    ? "red"
                                                    : baseStyles,
                                                }),
                                              }}
                                              {...field}
                                              options={states}
                                              onChange={(selectedOption) => {
                                                field.onChange(
                                                  selectedOption.value
                                                ); // Update Controller's value
                                                GetAllCities(
                                                  selectedOption.value
                                                );
                                                setValue(
                                                  "state_id",
                                                  selectedOption
                                                );
                                                setValue("city_id", null);
                                                setValue("pincode_id", null);
                                              }}
                                            />
                                          )}
                                        />

                                        {errors.state_id && (
                                          <span className="text-danger">
                                            {errors.state_id.message}
                                          </span>
                                        )}
                                      </Row>
                                    </div>
                                  </Col>
                                )}

                                {type == "Vendor" && (
                                  <Col xl={6} xs={12}>
                                    <div className="main-form-section mt-3">
                                      <Row className="row justify-content-center mb-2">
                                        <Form.Label>City</Form.Label>

                                        <Controller
                                          name="city_id" // name of the field
                                          {...register("city_id", {
                                            required: "Select City",
                                          })}
                                          control={control}
                                          render={({ field }) => (
                                            <Select
                                              styles={{
                                                control: (baseStyles) => ({
                                                  ...baseStyles,
                                                  borderColor: errors.city_id
                                                    ? "red"
                                                    : baseStyles,
                                                }),
                                              }}
                                              {...field}
                                              options={cities}
                                              onChange={(selectedOption) => {
                                                field.onChange(
                                                  selectedOption.value
                                                ); // Update Controller's value
                                                GetAllPincode(
                                                  selectedOption.value
                                                );
                                                setValue(
                                                  "city_id",
                                                  selectedOption
                                                );
                                              }}
                                            />
                                          )}
                                        />

                                        {errors.city_id && (
                                          <span className="text-danger">
                                            {errors.city_id.message}
                                          </span>
                                        )}
                                      </Row>
                                    </div>
                                  </Col>
                                )}

                                {type == "Vendor" && (
                                  <Col xl={6} xs={12}>
                                    <div className="main-form-section mt-3">
                                      <Row className="row justify-content-center mb-2">
                                        <Form.Label>Pincode</Form.Label>

                                        <Controller
                                          name="pincode_id" // name of the field
                                          {...register("pincode_id", {
                                            required: "Select Pincode",
                                          })}
                                          control={control}
                                          render={({ field }) => (
                                            <Select
                                              styles={{
                                                control: (baseStyles) => ({
                                                  ...baseStyles,
                                                  borderColor: errors.pincode_id
                                                    ? "red"
                                                    : baseStyles,
                                                }),
                                              }}
                                              {...field}
                                              options={pincode}
                                            />
                                          )}
                                        />

                                        {errors.pincode_id && (
                                          <span className="text-danger">
                                            {errors.pincode_id.message}
                                          </span>
                                        )}
                                      </Row>
                                    </div>
                                  </Col>
                                )}
                              </div>

                              <Form.Group className="form-group">
                                <Form.Label>Enter Password</Form.Label>

                                {/* <Form.Control type="text" placeholder="Enter Password" /> */}
                                <Form.Control
                                  type={showPassword ? "text" : "password"}
                                  placeholder="Enter Password*"
                                  name="password"
                                  {...register("password", {
                                    required: "Password is required",
                                    minLength: {
                                      value: 8,
                                      message:
                                        "Password must be at least 8 characters long",
                                    },
                                    pattern: {
                                      value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                                      message:
                                        "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                                    },
                                  })}
                                  onChange={(e) => setPassword(e.target.value)}
                                />
                                <div className="eye-icc">
                                  <FontAwesomeIcon
                                    icon={iconOne}
                                    className="eye-icc"
                                    onClick={() =>
                                      togglePasswordVisibility("password")
                                    }
                                  />
                                </div>
                              </Form.Group>

                              <Form.Group className="form-group">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control
                                  type={showPasswordOne ? "text" : "password"}
                                  placeholder="Re-Enter Password*"
                                  name="re_password"
                                  {...register("re_password", {
                                    required: "Re-Password is required",
                                  })}
                                  onChange={(e) =>
                                    setPasswordAgain(e.target.value)
                                  }
                                />
                                <div className="eye-icc">
                                  <FontAwesomeIcon
                                    icon={iconTwo}
                                    className="eye-icc"
                                    onClick={() =>
                                      togglePasswordVisibility(
                                        "reenterPassword"
                                      )
                                    }
                                  />
                                </div>
                                {errors?.re_password && (
                                  <sup className="text-danger">
                                    {errors?.re_password?.message}
                                  </sup>
                                )}
                                {password && (
                                  <PasswordChecklist
                                    rules={[
                                      "minLength",
                                      "specialChar",
                                      "number",
                                      "capital",
                                      "match",
                                    ]}
                                    minLength={8}
                                    value={password}
                                    valueAgain={passwordAgain}
                                    onChange={(isValid) => {}}
                                    style={{
                                      fontSize: "10px",
                                      padding: "4px",
                                      margin: "0",
                                    }}
                                  />
                                )}
                              </Form.Group>

                              <button
                                className="Login-btn"
                                onClick={handleSubmit(onSubmit)}
                              >
                                Create Account
                              </button>
                            </div>
                          </Tab.Pane>

                          <Tab.Pane eventKey="second">
                            <div className="form-div"></div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Row>
                    </Tab.Container>
                    <p className="lastcont">
                      Already have an account?
                      <Link to="/login" disabled>
                        Login
                      </Link>
                    </p>
                    <div className="mb-3">
                      <Form.Check
                        inline
                        label="By signing up, I agree to the Terms and Conditions"
                        name="termsAndConditions"
                        type="checkbox"
                        id="termsAndConditions"
                        {...register("termsAndConditions", {
                          required: "Need To Accept Terms And Condition ",
                        })}
                        onChange={handleCheckboxChange}
                      />
                    </div>{" "}
                    {errors?.termsAndConditions && (
                      <sup className="text-danger">
                        {errors?.termsAndConditions?.message}
                      </sup>
                    )}
                  </Form>
                </div>
              </div>
            </div>

            <RegisterModal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Signup;
