import React, { useRef, useState, useContext } from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import "./Tabs_section.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import parse from "html-react-parser";
import { Context } from "../../../utils/context";
const chunkArray = (array, size) => {
  const chunkedArr = [];
  for (let i = 0; i < array.length; i += size) {
    chunkedArr.push(array.slice(i, i + size));
  }
  return chunkedArr;
};
const Tabs_section = ({ data }) => {
  const projectChunks = chunkArray(data?.studio_projects || [], 6);

  const Reviews = data?.studio_reviews || [];

  const { IMG_URL } = useContext(Context);
  const formatTime = (timeString) => {
    const date = new Date(`1970-01-01T${timeString}Z`);
    return date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  };
  return (
    <>
      <section className="StudioTabs_section">
        <div className="container">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Nav variant="pills" className="">
              <Nav.Item>
                <Nav.Link eventKey="first">Features & Amenities</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">Charges</Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="third">Projects</Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="fourth">
                  Terms & Conditions Applied
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="fifth">Reviews</Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="first">
                <div className="tab-cont">
                  <h6 className="ttiill">
                    Studio set-up, Equipment’s, and facilities available are as
                    below:
                  </h6>

                  <ol>
                    <li>
                      {typeof data?.features_and_aminities === "string"
                        ? parse(data?.features_and_aminities)
                        : ""}
                    </li>
                  </ol>
                  {/* <ol>
                    <li>Flexible rental options: Hourly, daily, or long-term leases available.
                    </li>
                    <li>High-quality lighting equipment and backdrops.</li>
                    <li>Soundproofing for audio recording and live performances.</li>
                    <li>Comfortable seating and lounge areas for breaks and client meetings.</li>
                    <li>Wi-Fi access and multimedia connectivity.</li>
                    <li>On-site parking and convenient location for easy access.</li>
                  </ol> */}
                </div>
              </Tab.Pane>

              <Tab.Pane eventKey="second">
                <div className="tab-cont">
                  <h6 className="ttiill">{data?.name} Charges</h6>

                  <ol>
                    <li>
                      Half Day: INR. {data?.half_day_charges}/- (
                      {data?.open_24_hours ? (
                        "Open 24 Hours"
                      ) : (
                        <>
                          1st Half:{" "}
                          {new Date(
                            "1970-01-01T" + data?.first_h_start_time
                          ).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })}{" "}
                          ~{" "}
                          {new Date(
                            "1970-01-01T" + data?.first_h_end_time
                          ).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })}{" "}
                          , 2nd Half:{" "}
                          {new Date(
                            "1970-01-01T" + data?.second_h_start_time
                          ).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })}{" "}
                          ~{" "}
                          {new Date(
                            "1970-01-01T" + data?.second_h_end_time
                          ).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })}
                        </>
                      )}
                      )
                    </li>

                    <li>
                      Full Day: INR. {data?.full_day_charges}/- (
                      {data?.open_24_hours ? (
                        "Open 24 Hours"
                      ) : (
                        <>
                          {new Date(
                            "1970-01-01T" + data?.full_d_start_time
                          ).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })}{" "}
                          ~
                          {new Date(
                            "1970-01-01T" + data?.full_d_end_time
                          ).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })}
                        </>
                      )}
                      )
                    </li>

                    <li>
                      Hourly Basis: INR.{data?.hourly_basis_charges}/H (Min{" "}
                      {data?.minimum_hours} Hours Booking Required)
                    </li>
                  </ol>
                </div>
              </Tab.Pane>

              <Tab.Pane eventKey="third">
                <div className="tab-cont">
                  <div className="project-swiper">
                    <Swiper
                      pagination={{
                        dynamicBullets: true,
                      }}
                      modules={[Pagination]}
                      className="mySwiper"
                    >
                      {projectChunks?.map((chunk, index) => (
                        <SwiperSlide>
                          <div className="swiper-div">
                            <div className="row">
                              {chunk?.map((val, index) => (
                                <div className="col-sm-4 col-6 mb-4 ">
                                  <div className="image-div">
                                    <img
                                      className="project-img"
                                      src={IMG_URL + val?.image}
                                    />
                                    <div className="overlay-color"></div>
                                    <div className="overlay-content">
                                      <p className="name">{val?.name}</p>
                                      <p className="date">
                                        {val?.month} {val?.year}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                              {/* <div className='col-sm-4 col-6 mb-4 '>
                                      <div className='image-div'>
                                          <img className='project-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/Projects/project2.png'} />
                                          <div className='overlay-color'></div>
                                          <div className='overlay-content'>
                                              <p className='name'>Lame Advertise Shoot</p>
                                              <p className='date'>JUN 2024</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className='col-sm-4 col-6 mb-4 '>
                                      <div className='image-div'>
                                          <img className='project-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/Projects/project1.png'} />
                                          <div className='overlay-color'></div>
                                          <div className='overlay-content'>
                                              <p className='name'>Lame Advertise Shoot</p>
                                              <p className='date'>JUN 2024</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className='col-sm-4 col-6 mb-4 '>
                                      <div className='image-div'>
                                          <img className='project-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/Projects/project2.png'} />
                                          <div className='overlay-color'></div>
                                          <div className='overlay-content'>
                                              <p className='name'>Lame Advertise Shoot</p>
                                              <p className='date'>JUN 2024</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className='col-sm-4 col-6 mb-4 '>
                                      <div className='image-div'>
                                          <img className='project-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/Projects/project1.png'} />
                                          <div className='overlay-color'></div>
                                          <div className='overlay-content'>
                                              <p className='name'>Lame Advertise Shoot</p>
                                              <p className='date'>JUN 2024</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className='col-sm-4 col-6 mb-4 '>
                                      <div className='image-div'>
                                          <img className='project-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/Projects/project2.png'} />
                                          <div className='overlay-color'></div>
                                          <div className='overlay-content'>
                                              <p className='name'>Lame Advertise Shoot</p>
                                              <p className='date'>JUN 2024</p>
                                          </div>
                                      </div>
                                  </div> */}
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}

                      {/* <SwiperSlide>
                          <div className='swiper-div'>
                              <div className='row'>
                                  <div className='col-sm-4 col-6 mb-4 '>
                                      <div className='image-div'>
                                          <img className='project-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/Projects/project1.png'} />
                                          <div className='overlay-color'></div>
                                          <div className='overlay-content'>
                                              <p className='name'>Lame Advertise Shoot</p>
                                              <p className='date'>JUN 2024</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className='col-sm-4 col-6 mb-4 '>
                                      <div className='image-div'>
                                          <img className='project-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/Projects/project2.png'} />
                                          <div className='overlay-color'></div>
                                          <div className='overlay-content'>
                                              <p className='name'>Lame Advertise Shoot</p>
                                              <p className='date'>JUN 2024</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className='col-sm-4 col-6 mb-4 '>
                                      <div className='image-div'>
                                          <img className='project-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/Projects/project1.png'} />
                                          <div className='overlay-color'></div>
                                          <div className='overlay-content'>
                                              <p className='name'>Lame Advertise Shoot</p>
                                              <p className='date'>JUN 2024</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className='col-sm-4 col-6 mb-4 '>
                                      <div className='image-div'>
                                          <img className='project-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/Projects/project2.png'} />
                                          <div className='overlay-color'></div>
                                          <div className='overlay-content'>
                                              <p className='name'>Lame Advertise Shoot</p>
                                              <p className='date'>JUN 2024</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className='col-sm-4 col-6 mb-4 '>
                                      <div className='image-div'>
                                          <img className='project-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/Projects/project1.png'} />
                                          <div className='overlay-color'></div>
                                          <div className='overlay-content'>
                                              <p className='name'>Lame Advertise Shoot</p>
                                              <p className='date'>JUN 2024</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className='col-sm-4 col-6 mb-4 '>
                                      <div className='image-div'>
                                          <img className='project-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/Projects/project2.png'} />
                                          <div className='overlay-color'></div>
                                          <div className='overlay-content'>
                                              <p className='name'>Lame Advertise Shoot</p>
                                              <p className='date'>JUN 2024</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </SwiperSlide>

                      <SwiperSlide>
                          <div className='swiper-div'>
                              <div className='row'>
                                  <div className='col-sm-4 col-6 mb-4 '>
                                      <div className='image-div'>
                                          <img className='project-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/Projects/project1.png'} />
                                          <div className='overlay-color'></div>
                                          <div className='overlay-content'>
                                              <p className='name'>Lame Advertise Shoot</p>
                                              <p className='date'>JUN 2024</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className='col-sm-4 col-6 mb-4 '>
                                      <div className='image-div'>
                                          <img className='project-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/Projects/project2.png'} />
                                          <div className='overlay-color'></div>
                                          <div className='overlay-content'>
                                              <p className='name'>Lame Advertise Shoot</p>
                                              <p className='date'>JUN 2024</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className='col-sm-4 col-6 mb-4 '>
                                      <div className='image-div'>
                                          <img className='project-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/Projects/project1.png'} />
                                          <div className='overlay-color'></div>
                                          <div className='overlay-content'>
                                              <p className='name'>Lame Advertise Shoot</p>
                                              <p className='date'>JUN 2024</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className='col-sm-4 col-6 mb-4 '>
                                      <div className='image-div'>
                                          <img className='project-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/Projects/project2.png'} />
                                          <div className='overlay-color'></div>
                                          <div className='overlay-content'>
                                              <p className='name'>Lame Advertise Shoot</p>
                                              <p className='date'>JUN 2024</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className='col-sm-4 col-6 mb-4 '>
                                      <div className='image-div'>
                                          <img className='project-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/Projects/project1.png'} />
                                          <div className='overlay-color'></div>
                                          <div className='overlay-content'>
                                              <p className='name'>Lame Advertise Shoot</p>
                                              <p className='date'>JUN 2024</p>
                                          </div>
                                      </div>
                                  </div>
                                  <div className='col-sm-4 col-6 mb-4 '>
                                      <div className='image-div'>
                                          <img className='project-img' src={process.env.PUBLIC_URL + '/assets/images/studio-inner/Projects/project2.png'} />
                                          <div className='overlay-color'></div>
                                          <div className='overlay-content'>
                                              <p className='name'>Lame Advertise Shoot</p>
                                              <p className='date'>JUN 2024</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </SwiperSlide> */}
                    </Swiper>
                  </div>
                </div>
              </Tab.Pane>

              <Tab.Pane eventKey="fourth">
                <div className="tab-cont">
                  <h6 className="ttiill">
                    {data?.name} Terms & Conditions Applied
                  </h6>
                  <ol>
                    <li>
                      {typeof data?.terms_and_condition === "string"
                        ? parse(data?.terms_and_condition)
                        : ""}
                      {/* <li>For extra hours additional charges applied
                    </li>
                    <li>50% Advance for studio booking, Balance 50% before start of shoot
                    </li>
                    <li>Cancellation charges not applicable for prior 15 days cancellation. If canceled within 8 days or 72 hours before, 50% amount will be non refundable.</li> */}
                    </li>
                  </ol>
                </div>
              </Tab.Pane>

              {/* <Tab.Pane eventKey="fifth">
                <div className="tab-cont">
                  
                  <div className="rating-div">
                    <h6 className="ttiill">
                      Pritam Rathod{" "}
                      <span>
                        {" "}
                        (4 <FontAwesomeIcon icon={faStar} />){" "}
                      </span>
                    </h6>
                    <p className="review">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book.
                    </p>
                  </div>

                  <div className="rating-div">
                    <h6 className="ttiill">
                      Pritam Rathod{" "}
                      <span>
                        {" "}
                        (4 <FontAwesomeIcon icon={faStar} />){" "}
                      </span>
                    </h6>
                    <p className="review">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book.
                    </p>
                  </div>
                </div>
              </Tab.Pane> */}
              <Tab.Pane eventKey="fifth">
                <div className="tab-cont">
                  {Reviews.length > 0 ? (
                    Reviews?.map((name, index) => (
                      <div className="rating-div" key={index}>
                        <h6 className="ttiill">
                          {name?.user?.first_name}
                          <span>
                            ({name?.ratings} <FontAwesomeIcon icon={faStar} />)
                          </span>
                        </h6>
                        <p className="review">{name?.review_message}</p>
                      </div>
                    ))
                  ) : (
                    <div className="rating-div text-center">
                      <p className="review" style={{ fontSize: 20 }}>
                        No Reviews
                      </p>
                    </div>
                  )}
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </section>
    </>
  );
};

export default Tabs_section;
