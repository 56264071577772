import React, { useEffect, useRef, useState, useContext } from "react";
import "./why-fns.css";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Context } from "../../../utils/context";
import { AllFns, MakeUsSpecial } from "../../../utils/apis/Home/Homeapi";
import parse from "html-react-parser";
const Why_fns = () => {
  const [data, setData] = useState();
  const { IMG_URL } = useContext(Context);
  const getAllData = async () => {
    const res = await AllFns();
    if (res?.success) {
      setData(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };
  const [dataSpecial, setDataSpecial] = useState();
  const getAllDataspecial = async () => {
    const res = await MakeUsSpecial();
    if (res?.success) {
      setDataSpecial(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getAllData();
    getAllDataspecial();
  }, []);
  return (
    <>
      <section className="Why_fns_sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <img
                className="fns-img"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/homepage/why-fns/why-fns.png"
                }
              />
            </div>
            <div className="col-lg-6 ps-md-0 mt-lg-0 mt-5">
              <div className="slider-sec">
                <h1 className="title">Why FnS</h1>
                <h2 className="newand">
                  New and <br></br>
                  never-ending possibilities
                </h2>

                <div className="swiper-main">
                  <Swiper
                    pagination={{
                      type: "fraction",
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                  >
                    {data?.map((val) => (
                      <SwiperSlide>
                        <div className="slider-div">
                          <p className="slider-text">
                            {parse(val?.description)}
                          </p>
                        </div>
                      </SwiperSlide>
                    ))}
                    {/* <SwiperSlide>
                                            <div className='slider-div'>
                                                <p className='slider-text'>Find everything from professionally equipped studios
                                                    to unconventional rooms and residences.</p>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='slider-div'>
                                                <p className='slider-text'>Find everything from professionally equipped studios
                                                    to unconventional rooms and residences.</p>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='slider-div'>
                                                <p className='slider-text'>Find everything from professionally equipped studios
                                                    to unconventional rooms and residences.</p>
                                            </div>
                                        </SwiperSlide> */}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>

          <div className="this-make-sec">
            <h1 className="newand ">
              and <br></br>
              this makes us Special
            </h1>

            <div className="row mt-5">
              {dataSpecial?.map((val, index) => (
                <div className="col-md-6 mb-4">
                  <div className="special-card">
                    <img className="bg-img" src={IMG_URL + val?.image} />

                    <div className="overlay-content">
                      <div className="row">
                        <div className="col-3">
                          <div className="number-div">
                            <h1 className="number">0{++index}</h1>
                          </div>
                        </div>
                        <div className="col-9 text-end">
                          <h1 className="ttt">{val?.name}</h1>
                          <p className="subcont">{parse(val?.description)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              {/* <div className='col-md-6 mb-4'>
                                <div className='special-card'>
                                    <img className='bg-img' src={process.env.PUBLIC_URL + '/assets/images/homepage/why-fns/bg2.png'} />

                                    <div className='overlay-content'>
                                        <div className='row'>
                                            <div className='col-3'>
                                                <div className='number-div'>
                                                    <h1 className='number'>02</h1>
                                                </div>
                                            </div>
                                            <div className='col-9 text-end'>
                                                <h1 className='ttt'>Quiet Hours</h1>
                                                <p className='subcont'>Lorem ipsum dolor sit amet,
                                                    consectetur adipisicing elit, sed do eiusmod</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-6 mb-4'>
                                <div className='special-card'>
                                    <img className='bg-img' src={process.env.PUBLIC_URL + '/assets/images/homepage/why-fns/bg3.png'} />

                                    <div className='overlay-content'>
                                        <div className='row'>
                                            <div className='col-3'>
                                                <div className='number-div'>
                                                    <h1 className='number'>03</h1>
                                                </div>
                                            </div>
                                            <div className='col-9 text-end'>
                                                <h1 className='ttt'>Best Location</h1>
                                                <p className='subcont'>Lorem ipsum dolor sit amet,
                                                    consectetur adipisicing elit, sed do eiusmod</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-6 mb-4'>
                                <div className='special-card'>
                                    <img className='bg-img' src={process.env.PUBLIC_URL + '/assets/images/homepage/why-fns/bg4.png'} />

                                    <div className='overlay-content'>
                                        <div className='row'>
                                            <div className='col-3'>
                                                <div className='number-div'>
                                                    <h1 className='number'>04</h1>
                                                </div>
                                            </div>
                                            <div className='col-9 text-end'>
                                                <h1 className='ttt'>Cancellation</h1>
                                                <p className='subcont'>Lorem ipsum dolor sit amet,
                                                    consectetur adipisicing elit, sed do eiusmod</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Why_fns;
