import Cookies from "js-cookie";
import { v4 as uuidv4 } from 'uuid';
import {
    getData,
    postData,
    putData,
    editStatusData,
    deleteData,
    getDownloadDataExcel,
} from "../../api";

import { Context, variant } from "../../context";
import { useContext } from "react";




export const madeToOrder = async (data) => {
    try {
      const res = await postData(`/form/made-to-order`, data);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  export const RFQ = async (data) => {
    try {
      const res = await postData(`/form/rfq`, data);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  export const ContactUsForm = async (data) => {
    try {
      const res = await postData(`/contact/save`, data);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  export const EnquiryForm = async (data) => {
    try {
      const res = await postData(`/enquiry/save`, data);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  export const Suggetion = async (data) => {
    try {
      const res = await postData(`/form/suggestion`, data);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };

